import {
  Avatar,
  Box,
  Chip,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { tokens } from "../../theme";
import { styled } from "@mui/material/styles";
import { FormLabel, Tooltip, Grid, Modal } from "@mui/material";
import { useProSidebar } from "react-pro-sidebar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { GrView } from "react-icons/gr";
import { Space } from "antd";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material";
import { useForm } from "../../Utils/useForm";

import Controls from "../../Component/controls/Controls";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { RiderApprove, RiderReject } from "../../state/action/riderAction";
import { useSnackbar } from "notistack";
import moment from "moment";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "rgb(250 250 250)!important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const initialValues = {
  reason: "",
};

const RiderRequestList = (props) => {
  const {
    data,
    totalPages,
    page,
    totalRecords,
    setPage,
    flagName,
    handleChangePage,
    dataShorting,
    handleChangeRow,
    pageLimit,
  } = props;

  const [switchId, setSwitchId] = useState(null);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openRejectModel, setOpenRejectModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [limit, setLimit] = useState(10);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { collapsed, broken } = useProSidebar();
  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const RecordPerPage = [
    { id: "10", title: "10" },
    { id: "20", title: "20" },
  ];
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  //reject

  const handleRejectModel = () => setOpenRejectModel(true);
  const handleCloseReject = () => setOpenRejectModel(false);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //reject the rider
  const handleRejectStatus = async (e) => {
    e.preventDefault();

    const body = {
      reason: values.reason,
    };

    const res = await RiderReject(deleteId, body);

    if (res && res.status === true) {
      setOpenBreakdrop(false);
      handleCloseReject();
      refreshScreen();
      data();
      // RiderReqList();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //isMobileVerify document
  const IsVerify = (IsVerify) => {
    const statusColors = {
      0: {
        key: "Not Verified",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Verified",
        backgroundColor: "green",
        color: "#ede7dd",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "13px",
        }}
        sx={statusColors[IsVerify]}
        label={statusColors[IsVerify] ? statusColors[IsVerify].key : ""}
      />
    );
  };

  //isDocumentVerify document

  const IsVerifyDocument = (IsVerifyDocument) => {
    const statusColors = {
      0: {
        key: "Not verified",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Verified",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      2: {
        key: "Reject",
        backgroundColor: "Red",
        color: "#ede7dd",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[IsVerifyDocument]}
        label={
          statusColors[IsVerifyDocument]
            ? statusColors[IsVerifyDocument].key
            : ""
        }
      />
    );
  };

  //approve the status
  const handleApproveStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    // let queryString = `status=1`;
    if(switchId){
      const res = await RiderApprove(switchId);
      if (res && res.status === true) {
        setOpenBreakdrop(false);
        handleCloseSwitch();
        refreshScreen();
        data();
        enqueueSnackbar(res.message, {
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        setOpenBreakdrop(!openBackdrop);
  
        enqueueSnackbar(res?.response?.data?.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    }
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          maxHeight: "485px",
          overflow: "scroll",
        }}
      >
        <TableContainer component={Paper} sx={{ boxShadow: "0" }}>
          <Table
            aria-label="customized table"
            sx={{ width: { md: "160%", lg: "179%", xl: "100%" } }}
          >
            <TableHead>
              <TableRow hover>
                <StyledTableCell>Profile</StyledTableCell>

                <StyledTableCell onClick={() => dataShorting("name")}>
                  Name
                  {flagName ? (
                    <ArrowUpwardIcon
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                        position: "relative",
                        top: "3px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                        position: "relative",
                        top: "3px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell>Vehicle Type</StyledTableCell>
                <StyledTableCell>DOB</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Phone Number</StyledTableCell>
                <StyledTableCell>Is Document Verified</StyledTableCell>
                <StyledTableCell>Is Mobile Verified</StyledTableCell>
                <StyledTableCell>Referral Code</StyledTableCell>
                {/* <StyledTableCell>Rating</StyledTableCell> */}
                <StyledTableCell>DOJ</StyledTableCell>

                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => {
                return (
                  <StyledTableRow
                    hover
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/riderReqDetails", {
                        state: {
                          rider: row?.id,
                        },
                      });
                    }}
                  >
                    <StyledTableCell component="th" scope="row">
                      <Avatar
                        component="span"
                        variant="rounded"
                        alt="profile_image"
                        src={row?.profilePic}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row?.name || "-"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row?.vehicleType || "-"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.dateofBirth || "-"}
                    </StyledTableCell>

                    <StyledTableCell component="th" scope="row">
                      {row.email || "-"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.mobile || "-"}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {IsVerifyDocument(row.isDocumentVerifiyed)}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {IsVerify(row.isMobileVerifiyed) || "-"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.referalCode || "-"}
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      {row.avgRating ? (
                        <Typography>{row.avgRating} / 5</Typography>
                      ) : (
                        <Typography>0 / 5</Typography>
                      )}
                    </StyledTableCell> */}
                    <StyledTableCell>
                      {moment.parseZone(row.createdAt).format("MM-DD-YYYY LT")}
                    </StyledTableCell>
                    <StyledTableCell onClick={(e) => e.stopPropagation()}>
                      <Space size="middle">
                        <Tooltip placement="bottom" title="View Rider">
                          <button
                            className="action-button view"
                            onClick={() => {
                              navigate("/riderReqDetails", {
                                state: {
                                  rider: row?.id,
                                },
                              });
                            }}
                          >
                            {" "}
                            <GrView />{" "}
                          </button>
                        </Tooltip>
                        <Controls.Button
                          text="Approve"
                          sx={{
                            fontSize: "11px",
                            fontWeight: "500",
                            color: "#fff",
                            backgroundColor: "rgb(79, 70, 229)",
                            ":hover": { bgcolor: "rgb(79, 70, 229)" },
                            textTransform: "none",
                            borderRadius: "50px",
                            boxShadow: 0,
                          }}
                          onClick={(e) => {
                            handleSwitchModel();
                            setSwitchId(row.id);
                          }}
                        />
                        <Controls.Button
                          text="Reject"
                          sx={{
                            fontSize: "11px",
                            fontWeight: "500",
                            color: "#fff",
                            backgroundColor: "rgb(239, 83, 80)",
                            ":hover": { bgcolor: "rgb(239, 83, 80)" },
                            textTransform: "none",
                            borderRadius: "50px",
                            boxShadow: 0,
                          }}
                          onClick={(e) => {
                            handleRejectModel();
                            setDeleteId(row.id);
                          }}
                        />
                      </Space>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
          {data && data.length === 0 ? (
            <div
              className="noDataFound"
              style={{
                display: "flex",
              }}
            >
              {" "}
              No Data Found
            </div>
          ) : null}
        </TableContainer>
      </Box>

      <Box
        sx={{
          display: {
            xs: "block",
            sm: "flex",
            md: "flex",
            lg: "flex",
          },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: {
            xs: "10px",
            sm: "20px",
            md: "20px",
            lg: "20px",
          },
          marginRight: {
            xs: "10px",
            sm: "15px",
            md: "15px",
            lg: "15px",
          },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing{" "}
            {page * pageLimit > totalRecords
              ? totalRecords
              : page * pageLimit}{" "}
            of {totalRecords} Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>
            <Select
              className="recordLimit"
              sx={{
                color: "#000",
                height: "40px",
                fontSize: "15px",
                borderRadius: "5px",
                border: 1,
                paddingLeft: "10px",
                borderColor: "#000",
                ".MuiSvgIcon-root ": {
                  fill: "black !important",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              id="limites"
              name="limites"
              value={pageLimit}
              onChange={(e) => {
                handleChangeRow(e.target.value);
              }}
            >
              {RecordPerPage.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name || item.title}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>
      <Modal
        keepMounted
        open={openSwitchModel}
        onClose={handleCloseSwitch}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Approve Rider
          </Typography>
          <label className="fontLight">
            Are you sure to want to approve the rider?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{ backgroundColor: "#fff", color: "#000" }}
                text="Cancel"
                onClick={handleCloseSwitch}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Approve"
                onClick={() => handleApproveStatus(switchId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <label className="fontLight">
            Are you sure to want to reject this record?
          </label>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseDelete} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Reject"
                style={{ backgroundColor: "red" }}
                onClick={() => handleRejectStatus(deleteId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openRejectModel}
        onClose={handleCloseReject}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Reason
          </Typography>
          <form onSubmit={handleRejectStatus}>
            <Box>
              <Box sx={{ marginTop: "30px" }}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Reason"
                  name="reason"
                  value={values.reason}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Box>
              <Grid container style={{ marginTop: "2rem" }}>
                <Grid item xs={9} className="text-end">
                  <Controls.Button
                    sx={{
                      backgroundColor: "#fff",
                      ":hover": { color: "#fff" },
                      marginRight: { xs: "10px", sm: "0px" },
                      color: "#000",
                    }}
                    text="Cancel"
                    onClick={handleCloseReject}
                  />
                </Grid>
                <Grid item xs={3} className="text-end">
                  <Controls.Button
                    sx={{ marginRight: "7px" }}
                    text="Save"
                    type="submit"
                  />
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default RiderRequestList;
