import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  InputBase,
  Pagination,
  Tooltip,
  Divider,
  Chip,
  InputAdornment,
  TextField,
  Slider,
  Autocomplete,
  Avatar,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import Controls from "../../Component/controls/Controls";
import { Drawer, FormLabel } from "@mui/material";
import { Form, useForm } from "../../Utils/useForm";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Box, Grid } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Space } from "antd";
import { GrView } from "react-icons/gr";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { getproductDropdown } from "../../state/action/productAction";
import { useProSidebar } from "react-pro-sidebar";
import RiderRequestList from "./RiderRequestList";

import {
  DeleteRider,
  getRiderList,
  getVehicleDropdownList,
  RiderStatusUpdate,
} from "../../state/action/riderAction";
import { useSnackbar } from "notistack";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { getWithDrawDetailsList } from "../../state/action/withdrawAction";

const initialValues = {
  email: "",
  name: "",
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "rgb(250 250 250)!important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));
const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiTextField-root {
    padding-top: 20px;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 2.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 70%) !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128, 0.5);
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #000;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    color: #4f46e5 !important;
    border-color: #4f46e5;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;

const whitePagination = styled(TablePagination)`
  & css-at26bj-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
    color: black;
  }
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

export default function CustomizedTables() {
  const [value, setValue] = useState("1");
  const [riderLists, setRiderLists] = useState([]);
  const [switchId, setSwitchId] = useState(null);
  const [checked, setChecked] = useState(true);
  const [status, setStatus] = useState();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openStatusModel, setOpenStatusModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [search, setSearch] = useState("");
  const [flagName, setflagName] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortFlag, setSortFlag] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [id, setId] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [sortBy, setSortBy] = useState("");
  const [bankList, setBankList] = useState({});
  const [detailsId, setDetailsId] = useState("");

  const { collapsed, broken } = useProSidebar();

  const riderStatus = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
  ];

  const RecordPerPage = [
    { id: "10", title: "10" },
    { id: "20", title: "20" },
    { id: "30", title: "30" },
    { id: "40", title: "40" },
    { id: "50", title: "50" },
  ];

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(1);
  };

  const { state } = useLocation();

  //delete
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  //status change
  const handleStatusModel = () => setOpenStatusModel(true);
  const handleCloseStatus = () => setOpenStatusModel(false);

  //View
  const [openViewModel, setOpenViewModel] = useState(false);
  const handleCloseView = () => setOpenViewModel(false);
  const handleViewModel = (ids) => {
    setOpenViewModel(true);
    BankListing(ids);
  };

  const handleChangePage = (e, value) => {
    if (fieldName == "name") {
      getriderListing(value, fieldName, flagName);
    } else {
      getriderListing(value, "", "");
    }
    setPage(value);
  };

  const handleChangeRow = (event) => {
    if (fieldName == "name") {
      getriderListing(page, fieldName, flagName, event);
    } else {
      getriderListing(page, "", "", event);
    }
  };

  // rider List
  useEffect(() => {
    getriderListing(page, "", "");
  }, [filterData, search, value]);
  const Role = useSelector((state) => state?.auth?.user);
  const getriderListing = async (page, name = "", flag, limit) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    let recordLimit = limit ? limit : pageLimit;
    let companyId = Role.role == 2 ? Role.id : '';
    let queryString = `?status=${
      value == 2 ? [0, 2] : 1
    }&page=${page}&search=${search}${string}&limit=${recordLimit}&sortby=${name}&sortFlag=${flag}&companyId=${companyId}`;

    const res = await getRiderList(queryString);
    if (res.status === true) {
      setRiderLists(res.data);
      setTotalRecords(res.totalRiders);
      setTotalPages(Math.ceil(res.totalRiders / recordLimit));
      setPageLimit(recordLimit);

      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
      setPage(page);
    } else {
      setRiderLists([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const dataShorting = (key) => {
    if (key === "name") {
      setflagName(!flagName);
      getriderListing(page, key, !flagName);
    } else {
      setflagName(false);
      setSortFlag(false);
    }

    setSortBy(key);
    setFieldName(key);
  };

  //status Change
  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const res = await RiderStatusUpdate(switchId);

    if (res && res.status === true) {
      if (fieldName == "name") {
        getriderListing(page, fieldName, flagName);
      } else {
        getriderListing(page, "");
      }
      setOpenBreakdrop(false);
      handleCloseStatus();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //delete
  const handleDelete = async () => {
    const res = await DeleteRider(id);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "name") {
        getriderListing(page, fieldName, flagName);
      } else {
        getriderListing(page, "");
      }
      handleCloseDelete();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //filter
  const handleSubmit = (e) => {
    e.preventDefault();

    setFilterData(!filterData);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };

  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };
  //isMobileVerify document
  const IsVerify = (IsVerify) => {
    const statusColors = {
      0: {
        key: "Not Verified",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Verified",
        backgroundColor: "green",
        color: "#ede7dd",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "13px",
        }}
        sx={statusColors[IsVerify]}
        label={statusColors[IsVerify] ? statusColors[IsVerify].key : ""}
      />
    );
  };

  //pro status
  const proStatus = (proStatus) => {
    const statusColors = {
      1: {
        key: "Yes",
        color: "green",
      },
      0: {
        key: "No",
        color: "red",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[proStatus]}
        label={statusColors[proStatus] ? statusColors[proStatus].key : ""}
      />
    );
  };

  //locationdata
  const locationData = (latitude, lng) => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${latitude},${lng}`,
      "_blank"
    );
  };

  //bank details

  const BankListing = async (id) => {
    const res = await getWithDrawDetailsList(id);
    if (res.status === true) {
      setBankList(res.data);
    } else {
      setBankList([]);
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={7} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                Riders
              </Typography>
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box
                display="flex"
                sx={{
                  justifyContent: {
                    xs: "block",
                    sm: "flex-end",
                    md: "flex-end",
                    lg: "flex-end",
                  },
                  marginTop: { xs: "20px", md: "0", lg: "0" },
                }}
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: "gray",
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" } }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<FilterAltIcon />}
                    onClick={openFilterDrawer}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "30px",
                      
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "rgb(241, 245, 249)",
              mx: 2,
            }}
          >
            <Box
              sx={{
                paddingTop: "10px",
                backgroundColor: "#fff",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                overflow: "auto"
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Riders List"
                  value="1"
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                />
                {Role.role == 1 ?(
                  <Tab
                    label="Rider Pending List"
                    value="2"
                    sx={{
                      color: "rgb(107, 114, 128)",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "none",
                      paddingBottom: "20px",
                    }}
                  />
                ): null}
              </TabList>

              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
              <TabPanel value="1">
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderTopRightRadius: "20px",
                    borderTopLeftRadius: "20px",
                    height: "500px",
                    overflowY: "scroll",
                  }}
                >
                  <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                    <Table
                      aria-label="customized table"
                      sx={{ width: { md: "160%", lg: "176%", xl: "100%" } }}
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Profile</StyledTableCell>

                          <StyledTableCell onClick={() => dataShorting("name")}>
                            Name
                            {flagName ? (
                              <ArrowUpwardIcon
                                sx={{
                                  color: "rgba(107, 114, 128, .5)",
                                  fontSize: "17px",
                                  marginLeft: "3px",
                                }}
                              />
                            ) : (
                              <ArrowDownwardIcon
                                sx={{
                                  color: "rgba(107, 114, 128, .5)",
                                  fontSize: "17px",
                                  marginLeft: "3px",
                                }}
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell>Is Pro</StyledTableCell>
                          <StyledTableCell>Vehicle Type</StyledTableCell>
                          <StyledTableCell>DOB</StyledTableCell>
                          <StyledTableCell>Email</StyledTableCell>
                          <StyledTableCell>Phone Number</StyledTableCell>

                          <StyledTableCell>Is Verified</StyledTableCell>
                          <StyledTableCell>Referral Code</StyledTableCell>
                          <StyledTableCell>Rating</StyledTableCell>
                          <StyledTableCell>DOJ</StyledTableCell>
                          <StyledTableCell>Status</StyledTableCell>
                          {Role.role == 1 ?(
                          <StyledTableCell>Action</StyledTableCell>
                          ): null}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {riderLists?.map((row) => {
                          return (
                            <>
                              <StyledTableRow
                                hover
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  navigate("/riderdetails", {
                                    state: row.id,
                                  });
                                }}
                              >
                                <StyledTableCell component="th" scope="row">
                                  <Avatar
                                    component="span"
                                    variant="rounded"
                                    alt="profile_image"
                                    src={row?.profilePic}
                                  />
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {row?.name || "-"}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {proStatus(row?.isPro) || "-"}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {row?.vehicleType || "-"}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {row.dateofBirth || "-"}
                                </StyledTableCell>

                                <StyledTableCell component="th" scope="row">
                                  {row?.email ? (
                                    <Typography>{row?.email}</Typography>
                                  ) : (
                                    <Typography>-</Typography>
                                  )}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {row.mobile || "-"}
                                </StyledTableCell>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  onClick={(e) => e.stopPropagation()}
                                  sx={{
                                    display: "flex",
                                  }}
                                >
                                  {IsVerify(row.isDocumentVerifiyed) || "-"}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {row.referalCode || "-"}
                                </StyledTableCell>

                                <StyledTableCell>
                                  {row.avgRating ? (
                                    <Typography>{row.avgRating} / 5</Typography>
                                  ) : (
                                    <Typography>0 / 5</Typography>
                                  )}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {moment.parseZone(row.createdAt).format(
                                    "MM-DD-YYYY LT"
                                  )}
                                </StyledTableCell>

                                <StyledTableCell
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <Controls.Switch
                                    checked={row.isActive == 1 ? true : false}
                                    onChange={(e) => {
                                      handleStatusModel();
                                      setStatus(
                                        e.target.checked == true
                                          ? "Active"
                                          : "InActive"
                                      );
                                      setSwitchId(row.id);
                                      setChecked(e.target.checked);
                                    }}
                                    name="checkedB"
                                    color="primary"
                                    sx={{ marginLeft: "40px" }}
                                  />
                                </StyledTableCell>
                                {Role.role == 1 ?(
                                <StyledTableCell
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <Space size="middle">
                                    <Tooltip
                                      placement="bottom"
                                      title="Location"
                                    >
                                      <button
                                        className="action-button"
                                        onClick={(e) => {
                                          locationData(
                                            row?.lattitude,
                                            row.longitude
                                          );
                                        }}
                                      >
                                        {" "}
                                        <LocationOnIcon
                                          sx={{ color: "#000" }}
                                        />{" "}
                                      </button>
                                    </Tooltip>
                                    <Tooltip
                                      placement="bottom"
                                      title="View Bankdetails"
                                    >
                                      <button className="action-button view">
                                        {" "}
                                        <AccountBalanceIcon
                                          onClick={() => {
                                            handleViewModel(row?.id);
                                          }}
                                          sx={{ color: "#000" }}
                                        />{" "}
                                      </button>
                                    </Tooltip>

                                    <Tooltip
                                      placement="bottom"
                                      title="View Rider"
                                    >
                                      <button
                                        className="action-button view"
                                        onClick={() => {
                                          navigate("/riderdetails", {
                                            state: row.id,
                                          });
                                        }}
                                      >
                                        {" "}
                                        <GrView />{" "}
                                      </button>
                                    </Tooltip>

                                    <Tooltip
                                      placement="bottom"
                                      title="Delete Rider"
                                    >
                                      <button
                                        className="action-button delete-btn"
                                        onClick={() => {
                                          setId(row.id);
                                          setDeleteId(row.id);
                                          handleDeleteModel();
                                        }}
                                      >
                                        {" "}
                                        <RiDeleteBinLine />{" "}
                                      </button>
                                    </Tooltip>
                                  </Space>
                                </StyledTableCell>
                                ): null}
                              </StyledTableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </Table>
                    {riderLists && riderLists.length === 0 ? (
                      <div
                        className="noDataFound"
                        style={{
                          display: "flex",
                        }}
                      >
                        {" "}
                        No Data Found
                      </div>
                    ) : null}
                  </TableContainer>
                </Box>

                <Box
                  sx={{
                    display: {
                      xs: "block",
                      sm: "flex",
                      md: "flex",
                      lg: "flex",
                    },
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: {
                      xs: "10px",
                      sm: "20px",
                      md: "20px",
                      lg: "20px",
                    },
                    marginRight: {
                      xs: "10px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "14px",
                      marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                      gap: 2,
                      width: { sm: "100%", md: "50%" },
                    }}
                    display="flex"
                    alignItems="center"
                  >
                    <Box>
                      {" "}
                      Showing{" "}
                      {page * pageLimit > totalRecords
                        ? totalRecords
                        : page * pageLimit}{" "}
                      of {totalRecords} Results
                    </Box>
                    <Box display="flex" sx={{ gap: 1 }} alignItems="center">
                      <Typography>Records per page</Typography>
                      <Select
                        className="recordLimit"
                        sx={{
                          color: "#000",
                          height: "40px",
                          fontSize: "15px",
                          borderRadius: "5px",
                          border: 1,
                          paddingLeft: "10px",
                          borderColor: "#000",
                          ".MuiSvgIcon-root ": {
                            fill: "black !important",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                        }}
                        id="limites"
                        name="limites"
                        value={pageLimit}
                        onChange={(e) => {
                          handleChangeRow(e.target.value);
                        }}
                      >
                        {RecordPerPage.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name || item.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Box>
                  </Box>
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handleChangePage}
                    variant="outlined"
                    shape="rounded"
                    sx={{
                      "& .MuiButtonBase-root ": {
                        color: "#000 !important",
                      },
                      "& .Mui-selected ": {
                        background: "#4b4b4e70 !important",
                      },
                      "& .Mui-disabled ": {
                        background: "#6c696966 !important",
                        color: "#000000 !important",
                        opacity: 1,
                      },
                    }}
                  />
                </Box>
              </TabPanel>

              <TabPanel value="2">
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderTopRightRadius: "20px",
                    borderTopLeftRadius: "20px",
                  }}
                >
                  <RiderRequestList
                    data={riderLists}
                    dataShorting={dataShorting}
                    flagName={flagName}
                    totalPages={totalPages}
                    page={page}
                    totalRecords={totalRecords}
                    setPage={setPage}
                    fieldName={fieldName}
                    handleChangePage={handleChangePage}
                    handleChangeRow={handleChangeRow}
                    pageLimit={pageLimit}
                   
                  />
                </Box>
              </TabPanel>
            </Box>
          </Box>
        </TabContext>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                >
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
                <StyledFormLabel htmlFor="name">Name</StyledFormLabel>
                <WhiteBorderTextField
                  className="input"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  sx={{ mt: 0 }}
                />
                <StyledFormLabel htmlFor="name">Phone Number</StyledFormLabel>
                <WhiteBorderTextField
                  className="input"
                  fullWidth
                  id="mobile"
                  name="mobile"
                  value={values.mobile}
                  onChange={handleInputChange}
                  sx={{ mt: 0 }}
                />
                <StyledFormLabel htmlFor="name">Email</StyledFormLabel>
                <WhiteBorderTextField
                  className="input"
                  fullWidth
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  sx={{ mt: 0 }}
                />
              </Box>

              <Box>
                <StyledFormLabel htmlFor="hsnCode">Status</StyledFormLabel>

                <Controls.SelectBox
                  className="input"
                  
                  fullWidth
                  id="isActive"
                  name="isActive"
                  value={values.isActive}
                  onChange={handleInputChange}
                  options={riderStatus}
                />
              </Box>
            </Box>
            <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
              <button className="cancel-filter" onClick={closeFilterDrawer}>
                Reset
              </button>
              <button
                className="order-filter-button"
                type="submit"
                style={{
                  cursor: "pointer",
                }}
              >
                Filter
              </button>
            </Stack>
          </form>
        </Drawer>

        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: {
                xs: "50%",
                sm: broken ? "50%" : collapsed ? "50%" : "65%",
                md: collapsed ? "50%" : "60%",
                lg: collapsed ? "50%" : "55%",
              },
              transform: "translate(-50%, -50%)",
              width: { xs: "320px", sm: "400px" },
              border: "0",
              outline: "0",
              bgcolor: "#fff",
              borderRadius: "10px",
              p: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Delete Record
            </Typography>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    marginRight: { xs: "10px", sm: "0px" },
                  }}
                  text="Cancel"
                  onClick={handleCloseDelete}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Delete"
                  onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openStatusModel}
          onClose={handleCloseStatus}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="modelBox"
            sx={{
              position: "absolute",
              top: "50%",
              left: {
                xs: "50%",
                sm: broken ? "50%" : collapsed ? "50%" : "65%",
                md: collapsed ? "50%" : "60%",
                lg: collapsed ? "50%" : "55%",
              },
              transform: "translate(-50%, -50%)",
              width: { xs: "320px", sm: "400px" },
              border: "0",
              outline: "0",
              bgcolor: "#fff",
              borderRadius: "10px",
              p: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Status Change
            </Typography>
            <label className="fontLight">
              Are you sure change status to {status} ?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    marginRight: { xs: "10px", sm: "0px" },
                  }}
                  text="Cancel"
                  onClick={handleCloseStatus}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Change"
                  onClick={() => handleChangeStatus(switchId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openViewModel}
          onClose={handleCloseView}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="modelBox"
            sx={{
              position: "absolute",
              top: "50%",
              left: {
                xs: "50%",
                sm: broken ? "50%" : collapsed ? "50%" : "65%",
                md: collapsed ? "50%" : "60%",
                lg: collapsed ? "50%" : "55%",
              },
              transform: "translate(-50%, -50%)",
              width: { xs: "320px", sm: "400px" },
              border: "0",
              outline: "0",
              bgcolor: "#fff",
              borderRadius: "10px",
              p: 3,
              height: "42%",
              overflow: "scroll",
            }}
          >
            <Box sx={{ display: "flex", my: 2, gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", fontWeight: "500" }}
                  >
                    Bank Name
                  </Typography>

                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
                <Box>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", marginRight: "106px", width: "100%" }}
                  >
                    {bankList?.bankName || "-"}
                  </Typography>
                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", my: 2, gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", fontWeight: "500", width: "119%" }}
                  >
                    Bank Account Holder Name
                  </Typography>

                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", marginLeft: "57px", width: "100%" }}
                  >
                    {bankList?.accountHolderName || "-"}
                  </Typography>
                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", my: 2, gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", fontWeight: "500" }}
                  >
                    Bank Account Number
                  </Typography>

                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", marginLeft: "57px", width: "100%" }}
                  >
                    {bankList?.accountNumber || "-"}
                  </Typography>
                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", my: 2, gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", fontWeight: "500" }}
                  >
                    Bank IFSC Code
                  </Typography>

                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", marginLeft: "57px", width: "100%" }}
                  >
                    {bankList?.bankIFSCCode || "-"}
                  </Typography>
                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", my: 2, gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", fontWeight: "500" }}
                  >
                    Bank Address
                  </Typography>

                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", marginLeft: "57px", width: "100%" }}
                  >
                    {bankList?.bankAddress || "-"}
                  </Typography>
                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
