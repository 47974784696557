import { useRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Avatar,
  Box,
  Chip,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import { getPendingCompanyRiderLists } from "../../state/action/companyAction";
import { useProSidebar } from "react-pro-sidebar";
import { IconButton } from "@mui/material";
import camera from "../../assets/camera.svg";
import { styled } from "@mui/material/styles";
import { CenterFocusStrong, Close, SafetyDivider } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Form } from "../../Utils/useForm";
import { useLocation } from "react-router";
import "./CompanyRiderPending.css";
import { tokens } from "../../theme";
import { FormLabel, Tooltip, Grid, Modal } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { GrView } from "react-icons/gr";
import { Space } from "antd";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material";
import { useForm } from "../../Utils/useForm";

import Controls from "../../Component/controls/Controls";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { CompanyRiderApprove, CompanyRiderReject } from "../../state/action/companyAction";
import moment from "moment";

const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});
const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const CompanyRiderPending = (props) => {
  const {
    open,
    setOpen,
    handleClickOpen,
    handleClose,
    stateData,
    data,
    flagName,
    handleChangePage,
    handleChangeRow,
    isEdit,
  } = props;


  const { collapsed, broken } = useProSidebar();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [PendingCompanyRiderLists, setPendingCompanyRiderLists] = useState([]);

  const [emptyData, setEmptyData] = useState(false);

  const [pageLimit, setPageLimit] = useState(10);
  const [refresh, setRefresh] = useState(false);

  const [totalRecords, setTotalRecords] = useState(3);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [sortFlag, setSortFlag] = useState(false);

  const maxSize = 2048;

//image
const [image, setImage] = useState("");

const resetImage = () => {
  setImage("");
};

// Needhelp List
useEffect(() => {
  PendingCompanyRiderList(page, "", "");
}, [refresh, page, stateData]);

const PendingCompanyRiderList = async (page, name = "", flag, limit) => {
  setOpenBreakdrop(true);
  let string = "";
  let i = 0;
  for (let [key, value] of Object.entries(values)) {
    string += `&${key}=${value}`;
    i++;
  }
  let recordLimit = limit ? limit : pageLimit;

  let queryString = `?page=${page}&limit=${recordLimit}&sortby=${name}&sortFlag=${sortFlag}&stateData=${stateData}`;
  const res = await getPendingCompanyRiderLists(stateData);
  if (res.status === true) {
    setPendingCompanyRiderLists(res.data);
    setTotalRecords(res.total);
    setPageLimit(recordLimit);
    setTotalPages(Math.ceil(res.total / recordLimit));
    setSortFlag(!sortFlag);
    setOpenBreakdrop(false);
  } else {
    setPendingCompanyRiderLists([]);
    setOpenBreakdrop(false);
    setTotalRecords(0);
    setTotalPages(0);
  }
};

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "0",
    outline: "0",
    bgcolor: "#fff",
    borderRadius: "10px",
    p: 3,
  };
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#000",
      fontSize: 13,
      border: 0,
      fontWeight: 600,
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingLeft: "25px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
      color: "rgb(17, 24, 39)",
      border: 0,
      paddingLeft: "25px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&": {
      backgroundColor: "#fff",
      ":hover": { backgroundColor: "rgb(248 248 248)!important" },
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  
  const StyledChip = styled(Chip)(() => ({
    borderRadius: "10px",
    fontSize: "14px",
  }));
  
  const initialValues = {
    reason: "",
  };
  
    const [switchId, setSwitchId] = useState(null);
    const [openSwitchModel, setOpenSwitchModel] = useState(false);
    const [openRejectModel, setOpenRejectModel] = useState(false);
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [limit, setLimit] = useState(10);
    const [openBackdrop, setOpenBreakdrop] = useState(false);
    const refreshScreen = () => {
      setRefresh(!refresh);
    };
  
    const RecordPerPage = [
      { id: "10", title: "10" },
      { id: "20", title: "20" },
    ];
  
    const handleSwitchModel = () => setOpenSwitchModel(true);
    const handleCloseSwitch = () => setOpenSwitchModel(false);
    const handleCloseDelete = () => setOpenDeleteModel(false);
    const handleDeleteModel = () => setOpenDeleteModel(true);

    const [confirmDialog, setConfirmDialog] = useState(false);

    const openConfirmDialog = () => {
      setConfirmDialog(true);
    };

    const close = () => {
      handleClose();
    };
  
    //reject
  
    const handleRejectModel = () => setOpenRejectModel(true);
    const handleCloseReject = () => setOpenRejectModel(false);
  
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
      useForm(initialValues);
  
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    //reject the rider
    const handleRejectStatus = async (e) => {
      e.preventDefault();
  
      const body = {
        reason: values.reason,
      };
  
      const res = await CompanyRiderReject(deleteId, body);
  
      if (res && res.status === true) {
        setOpenBreakdrop(false);
        handleCloseReject();
        refreshScreen();
        //data();
        // RiderReqList();
        enqueueSnackbar(res.message, {
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        setOpenBreakdrop(!openBackdrop);
  
        enqueueSnackbar(res.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    };
  
    //isMobileVerify document
    const IsVerify = (IsVerify) => {
      const statusColors = {
        0: {
          key: "Not Verified",
          backgroundColor: "#ede7dd",
          color: "#fc7e00",
        },
        1: {
          key: "Verified",
          backgroundColor: "green",
          color: "#ede7dd",
        },
      };
  
      return (
        <StyledChip
          style={{
            height: "30px",
            fontSize: "13px",
          }}
          sx={statusColors[IsVerify]}
          label={statusColors[IsVerify] ? statusColors[IsVerify].key : ""}
        />
      );
    };
  
    //isDocumentVerify document
  
    const IsVerifyDocument = (IsVerifyDocument) => {
      const statusColors = {
        0: {
          key: "Not verified",
          backgroundColor: "#ede7dd",
          color: "#fc7e00",
        },
        1: {
          key: "Verified",
          backgroundColor: "green",
          color: "#ede7dd",
        },
        2: {
          key: "Reject",
          backgroundColor: "Red",
          color: "#ede7dd",
        },
      };
  
      return (
        <StyledChip
          style={{
            height: "30px",
            fontSize: "15px",
          }}
          sx={statusColors[IsVerifyDocument]}
          label={
            statusColors[IsVerifyDocument]
              ? statusColors[IsVerifyDocument].key
              : ""
          }
        />
      );
    };
  
    //approve the status
    const handleApproveStatus = async () => {
      setOpenBreakdrop(true);
      setOpenBreakdrop(!openBackdrop);
      // let queryString = `status=1`;
      if(switchId){
        const res = await CompanyRiderApprove(switchId);
        if (res && res.status === true) {
          setOpenBreakdrop(false);
          handleCloseSwitch();
          refreshScreen();
          //data();
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          setOpenBreakdrop(!openBackdrop);
    
          enqueueSnackbar(res?.response?.data?.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    };
  

  return (
    <div>
      {/* add new user modal */}
      <Dialog
        open={open}
        className={collapsed ? "" : "vehicleModel" + broken ? "noMargin" : ""}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          width: { xs: "100%", sm: "auto", md: "auto", lg: "80%", xl: "80%" },
          marginLeft: {
            xs: "0px",
            sm: collapsed ? "10%" : "28%",
            md: collapsed ? "10%" : "250px",
            lg: collapsed ? "12%" : "260px",
            xl: collapsed ? "12%" : "260px",
          },
          color: "black",
        }}
      >
          <DialogTitle
            sx={{
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "30px",
              display: "flex",
              justifyContent: "space-between",
              color: "black",
            }}
          >
            {isEdit ? "Edit Rider Details" : "Company Rider Pending List"}
            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  setImage("");
                  resetForm();
                  close();
                }}
              >
                <Close sx={{ color: "black" }} />
              </IconButton>
            </Tooltip>
          </DialogTitle>
          <Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          maxHeight: "485px",
          overflow: "scroll",
        }}
      >
        <TableContainer component={Paper} sx={{ boxShadow: "0" }}>
          <Table
            aria-label="customized table"
            sx={{ width: { md: "100%", lg: "100%", xl: "100%" } }}
          >
            <TableHead>
              <TableRow hover>
                <StyledTableCell>Profile</StyledTableCell>

                <StyledTableCell>
                  Name
                  {flagName ? (
                    <ArrowUpwardIcon
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                        position: "relative",
                        top: "3px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                        position: "relative",
                        top: "3px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell>Vehicle Type</StyledTableCell>
                <StyledTableCell>DOB</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Country Code</StyledTableCell>
                <StyledTableCell>Phone Number</StyledTableCell>
                <StyledTableCell>Is Document Verified</StyledTableCell>
                <StyledTableCell>Is Mobile Verified</StyledTableCell>
                <StyledTableCell>Referral Code</StyledTableCell>
                {/* <StyledTableCell>Rating</StyledTableCell> */}
                <StyledTableCell>DOJ</StyledTableCell>

                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {PendingCompanyRiderLists?.map((row) => {
                    return (
                  <StyledTableRow
                    hover
                    style={{ cursor: "pointer" }}
                  >
                    <StyledTableCell component="th" scope="row">
                      <Avatar
                        component="span"
                        variant="rounded"
                        alt="profile_image"
                        src={row?.profilePic}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row?.name || "-"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row?.vehicleType || "-"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.dateofBirth || "-"}
                    </StyledTableCell>

                    <StyledTableCell component="th" scope="row">
                      {row.email || "-"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.countryCode || "-"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.mobile || "-"}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {IsVerifyDocument(row.isDocumentVerifiyed)}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {IsVerify(row.isMobileVerifiyed) || "-"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.referalCode || "-"}
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      {row.avgRating ? (
                        <Typography>{row.avgRating} / 5</Typography>
                      ) : (
                        <Typography>0 / 5</Typography>
                      )}
                    </StyledTableCell> */}
                    <StyledTableCell>
                      {moment.parseZone(row.createdAt).format("MM-DD-YYYY LT")}
                    </StyledTableCell>
                    <StyledTableCell onClick={(e) => e.stopPropagation()}>
                      <Space size="middle">
                        {/* <Tooltip placement="bottom" title="View Rider">
                          <button
                            className="action-button view"
                            onClick={() => {
                              navigate("/riderReqDetails", {
                                state: {
                                  rider: row?.id,
                                },
                              });
                            }}
                          >
                            {" "}
                            <GrView />{" "}
                          </button>
                        </Tooltip> */}
                        <Controls.Button
                          text="Approve"
                          sx={{
                            fontSize: "11px",
                            fontWeight: "500",
                            color: "#fff",
                            backgroundColor: "rgb(79, 70, 229)",
                            ":hover": { bgcolor: "rgb(79, 70, 229)" },
                            textTransform: "none",
                            borderRadius: "50px",
                            boxShadow: 0,
                          }}
                          onClick={(e) => {
                            handleSwitchModel();
                            setSwitchId(row.id);
                          }}
                        />
                        <Controls.Button
                          text="Reject"
                          sx={{
                            fontSize: "11px",
                            fontWeight: "500",
                            color: "#fff",
                            backgroundColor: "rgb(239, 83, 80)",
                            ":hover": { bgcolor: "rgb(239, 83, 80)" },
                            textTransform: "none",
                            borderRadius: "50px",
                            boxShadow: 0,
                          }}
                          onClick={(e) => {
                            handleRejectModel();
                            setDeleteId(row.id);
                          }}
                        />
                      </Space>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
          {PendingCompanyRiderLists && PendingCompanyRiderLists.length === 0 ? (
            <div
              className="noDataFound"
              style={{
                display: "flex",
              }}
            >
              {" "}
              No Data Found
            </div>
          ) : null}
        </TableContainer>
      </Box>

      <Box
        sx={{
          display: {
            xs: "block",
            sm: "flex",
            md: "flex",
            lg: "flex",
          },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: {
            xs: "10px",
            sm: "20px",
            md: "20px",
            lg: "20px",
          },
          marginRight: {
            xs: "10px",
            sm: "15px",
            md: "15px",
            lg: "15px",
          },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing{" "}
            {page * pageLimit > totalRecords
              ? totalRecords
              : page * pageLimit}{" "}
            of {totalRecords} Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>
            <Select
              className="recordLimit"
              sx={{
                color: "#000",
                height: "40px",
                fontSize: "15px",
                borderRadius: "5px",
                border: 1,
                paddingLeft: "10px",
                borderColor: "#000",
                ".MuiSvgIcon-root ": {
                  fill: "black !important",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              id="limites"
              name="limites"
              value={pageLimit}
              onChange={(e) => {
                handleChangeRow(e.target.value);
              }}
            >
              {RecordPerPage.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name || item.title}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>
      <Modal
        keepMounted
        open={openSwitchModel}
        onClose={handleCloseSwitch}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Approve Rider
          </Typography>
          <label className="fontLight">
            Are you sure to want to approve the rider?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{ backgroundColor: "#fff", color: "#000" }}
                text="Cancel"
                onClick={handleCloseSwitch}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Approve"
                onClick={() => handleApproveStatus(switchId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <label className="fontLight">
            Are you sure to want to reject this record?
          </label>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseDelete} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Reject"
                style={{ backgroundColor: "red" }}
                onClick={() => handleRejectStatus(deleteId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openRejectModel}
        onClose={handleCloseReject}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: {
              xs: "50%",
              sm: broken ? "50%" : collapsed ? "50%" : "65%",
              md: collapsed ? "50%" : "60%",
              lg: collapsed ? "50%" : "55%",
            },
            transform: "translate(-50%, -50%)",
            width: { xs: "320px", sm: "400px" },
            border: "0",
            outline: "0",
            bgcolor: "#fff",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Reason
          </Typography>
          <form onSubmit={handleRejectStatus}>
            <Box>
              <Box sx={{ marginTop: "30px" }}>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Reason"
                  name="reason"
                  value={values.reason}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Box>
              <Grid container style={{ marginTop: "2rem" }}>
                <Grid item xs={9} className="text-end">
                  <Controls.Button
                    sx={{
                      backgroundColor: "#fff",
                      ":hover": { color: "#fff" },
                      marginRight: { xs: "10px", sm: "0px" },
                      color: "#000",
                    }}
                    text="Cancel"
                    onClick={handleCloseReject}
                  />
                </Grid>
                <Grid item xs={3} className="text-end">
                  <Controls.Button
                    sx={{ marginRight: "7px" }}
                    text="Save"
                    type="submit"
                  />
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
          <DialogContent>
      
          </DialogContent>
          {/* <DialogActions sx={{ margin: "15px", marginTop: "-22px" }}>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    marginRight: "-124px",
                    color: "#000",
                  }}
                  text="Cancel"
                  onClick={() => {
                    close();
                    setImage("");
                    resetForm();
                  }}
                />
              </Grid>
            </Grid>
          </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default CompanyRiderPending;
