import React, { useEffect, useState } from "react";
import {
  Typography,
  InputBase,
  Pagination,
  Tooltip,
  Divider,
  Drawer,
  Stack,
  styled,
  FormLabel,
  TableRow,
  tableCellClasses,
  TextField,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  Modal,
  Slider,
  Chip,
  MenuItem,
  Select,
} from "@mui/material";
import Controls from "../../Component/controls/Controls";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useLocation, useNavigate } from "react-router";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useForm } from "../../Utils/useForm";
import { AiOutlineCloseCircle } from "react-icons/ai";
import moment from "moment";
import {
  OrderStatusUpdate,
  getOrderDropdown,
  getOrderTransactionList,
  getPlanTransactionList,
} from "../../state/action/transactionAction";
import { useSnackbar } from "notistack";

const initialValues = {
  username: "",
  transactionID: "",
};

const RecordPerPage = [
  { id: "10", title: "10" },
  { id: "20", title: "20" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};
const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiTextField-root {
    padding-top: 20px;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 2.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 70%) !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128, 0.5);
  }
  & label.Mui-focused {
    color: #4f46e5 !important;
    border-color: black;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;

const OrderPayment = () => {
  const [value, setValue] = useState("1");
  const [search, setSearch] = useState("");
  const [flagName, setflagName] = useState(false);
  const [flagPrice, setflagPrice] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [sortFlag, setSortFlag] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [paymentModeList, setPaymentModeList] = useState("");

  const riderStatus = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
  ];
  const paymentStatusList = [
    { id: "1", title: "Success" },
    { id: "2", title: "Failed" },
    { id: "3", title: "Pending" },
  ];

  const { state } = useLocation();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();

  const handleChangePage = (e, value) => {
    if (fieldName == "orderNumber") {
      orderListing(value, fieldName, flagName);
    } else {
      orderListing(value, "", "");
    }
    setPage(value);
  };

  const handleChangeRow = (event) => {
    if (fieldName == "orderNumber") {
      orderListing(page, fieldName, flagName, event);
    } else {
      orderListing(page, "", "", event);
    }
  };

  useEffect(() => {
    orderListing(page, "", "");
  }, [filterData, search, page]);

  const orderListing = async (page, name = "", flag, limit) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = limit ? limit : pageLimit;

    let queryString = `?page=${page}&search=${search}${string}&limit=${recordLimit}&sortby=${name}&sortFlag=${flag}`;

    const res = await getOrderTransactionList(queryString);
    if (res.status === true) {
      setOrderList(res.data);
      setTotalRecords(res.total);
      setPageLimit(recordLimit);

      setTotalPages(Math.ceil(res.total / recordLimit));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
    } else {
      setOrderList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const dataShorting = (name) => {
    if (name === "orderNumber") {
      setflagName(!flagName);
      orderListing(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }

    setFieldName(name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  //payment status
  const paymentStatus = (paymentStatus) => {
    const statusColors = {
      1: {
        key: "Success",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      2: {
        key: "Failed",
        backgroundColor: "#FDE3E1",
        color: "#ff0000",
      },
      3: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      4: {
        key: "Refund",
        backgroundColor: "#f44336",
        color: "#ffffff",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "13px",
        }}
        sx={statusColors[paymentStatus]}
        label={
          statusColors[paymentStatus] ? statusColors[paymentStatus].key : ""
        }
      />
    );
  };

  //get paymentmode

  const getModeList = async () => {
    const res = await getOrderDropdown();
    if (res.status === true) {
      setPaymentModeList(res.data);
    } else {
      setPaymentModeList([]);
    }
  };

  // paymentmode List
  useEffect(() => {
    getModeList();
  }, []);
  return (
    <>
      <Box>
        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={7} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                Order Transaction
              </Typography>
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box
                display="flex"
                sx={{
                  justifyContent: {
                    xs: "block",
                    sm: "flex-end",
                    md: "flex-end",
                    lg: "flex-end",
                  },
                  marginTop: { xs: "20px", md: "0", lg: "0" },
                }}
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" } }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<FilterAltIcon />}
                    onClick={openFilterDrawer}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5Order Transactionpx",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            backgroundColor: "rgb(241, 245, 249)",
            width: "98%",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              border: 1,
              borderColor: "#eaeaea",
            }}
          >
            <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
              <Table
                aria-label="customized table"
                sx={{ width: { md: "140%", lg: "160%", xl: "100%" } }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Id</StyledTableCell>
                    <StyledTableCell>Payment Status</StyledTableCell>
                    <StyledTableCell
                      onClick={() => dataShorting("orderNumber")}
                    >
                      Order No
                      {flagName ? (
                        <ArrowUpwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>User Name</StyledTableCell>
                    <StyledTableCell>Rider Name</StyledTableCell>
                    <StyledTableCell>Transaction Id</StyledTableCell>
                    <StyledTableCell>Payment Mode</StyledTableCell>

                    <StyledTableCell>Amount</StyledTableCell>
                    <StyledTableCell>Discount</StyledTableCell>
                    <StyledTableCell>Payble Amount</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderList?.map((row) => {
                    return (
                      <StyledTableRow hover sx={{ cursor: "pointer" }}>
                        <StyledTableCell component="th" scope="row">
                          {row?.id || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000000c7",
                            }}
                          >
                            {paymentStatus(row?.paymentStatus) || "-"}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate("/orderdetails", {
                                state: row?.orderID,
                              });
                            }}
                          >
                            {row?.orderNumber || "-"}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate("/userdetails", {
                                state: {
                                  user: row?.userID,
                                },
                              });
                            }}
                          >
                            {row?.username || "-"}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.ridername ? (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigate("/riderdetails", {
                                  state: row?.riderID,
                                });
                              }}
                            >
                              {row?.ridername}
                            </Typography>
                          ) : (
                            <Typography>-</Typography>
                          )}
                        </StyledTableCell>

                        <StyledTableCell component="th" scope="row">
                          {row?.transactionID || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000000c7",
                            }}
                          >
                            {row?.paymentmodename || "-"}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>{row?.amount || "-"}</StyledTableCell>
                        <StyledTableCell>
                          {row?.discount || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.payableAmount || "-"}
                        </StyledTableCell>

                        <StyledTableCell>
                          {moment.parseZone(row.createdAt).format("MM-DD-YYYY LT")}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {orderList && orderList.length === 0 ? (
                <div
                  className="noDataFound"
                  style={{
                    display: "flex",
                  }}
                >
                  {" "}
                  No Data Found
                </div>
              ) : null}
            </TableContainer>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
              gap: 2,
              width: { sm: "100%", md: "50%" },
            }}
            display="flex"
            alignItems="center"
          >
            <Box>
              {" "}
              Showing{" "}
              {page * pageLimit > totalRecords
                ? totalRecords
                : page * pageLimit}{" "}
              of {totalRecords} Results
            </Box>
            <Box display="flex" sx={{ gap: 1 }} alignItems="center">
              <Typography>Records per page</Typography>
              <Select
                className="recordLimit"
                sx={{
                  color: "#000",
                  height: "40px",
                  fontSize: "15px",
                  borderRadius: "5px",
                  border: 1,
                  paddingLeft: "10px",
                  borderColor: "#000",
                  ".MuiSvgIcon-root ": {
                    fill: "black !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
                id="limites"
                name="limites"
                value={pageLimit}
                onChange={(e) => {
                  handleChangeRow(e.target.value);
                }}
              >
                {RecordPerPage.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                >
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{
                  marginTop: "8px",
                }}
              >
                <Divider sx={{ mt: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />{" "}
                <StyledFormLabel htmlFor="name">User Name</StyledFormLabel>
                <WhiteBorderTextField
                  className="input"
                  fullWidth
                  id="username"
                  name="username"
                  value={values.username}
                  onChange={handleInputChange}
                  sx={{ mt: 0 }}
                />
                <StyledFormLabel htmlFor="name">Rider Name</StyledFormLabel>
                <WhiteBorderTextField
                  className="input"
                  fullWidth
                  id="ridername"
                  name="ridername"
                  value={values.ridername}
                  onChange={handleInputChange}
                  sx={{ mt: 0 }}
                />
                <StyledFormLabel htmlFor="name">Transaction Id</StyledFormLabel>
                <WhiteBorderTextField
                  className="input"
                  fullWidth
                  id="transactionID"
                  name="transactionID"
                  value={values.transactionID}
                  onChange={handleInputChange}
                  sx={{ mt: 0 }}
                />
                <StyledFormLabel htmlFor="name">Payment Status</StyledFormLabel>
                <Controls.SelectBox
                  className="input"
                  fullWidth
                  id="paymentStatus"
                  name="paymentStatus"
                  value={values.paymentStatus}
                  onChange={handleInputChange}
                  options={paymentStatusList}
                />
              </Box>

              <Box>
                <StyledFormLabel htmlFor="taxRate">
                  Payment Mode
                </StyledFormLabel>
                <Controls.SelectBox
                  className="input"
                  fullWidth
                  id="paymentMode"
                  name="paymentMode"
                  value={values.paymentMode}
                  onChange={handleInputChange}
                  options={paymentModeList}
                />
              </Box>
            </Box>
            <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
              <button className="cancel-filter" onClick={closeFilterDrawer}>
                Reset
              </button>
              <button className="order-filter-button" type="submit">
                Filter
              </button>
            </Stack>
          </form>
        </Drawer>
      </Box>
    </>
  );
};

export default OrderPayment;
