import React, { useEffect, useState } from "react";
import {
  Typography,
  InputBase,
  Pagination,
  Tooltip,
  Divider,
  Drawer,
  Stack,
  styled,
  FormLabel,
  TableRow,
  tableCellClasses,
  TextField,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  Modal,
  Chip,
  MenuItem,
  Select,
  Avatar,
} from "@mui/material";
import Controls from "../../Component/controls/Controls";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {tokens } from "../../theme";
import { useForm } from "../../Utils/useForm";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { GrView } from "react-icons/gr";
import { Space } from "antd";
import { MdOutlineDone, MdOutlineCancelPresentation } from "react-icons/md";
import ImageNotFound from "../../assets/imageNotFound.png";

import moment from "moment";

import { useSnackbar } from "notistack";
import { useProSidebar } from "react-pro-sidebar";
import {
  WithStatusUpdate,
  getWithDrawDetailsList,
  getWithDrawList,
} from "../../state/action/withdrawAction";
const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiTextField-root {
    padding-top: 20px;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 2.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 70%) !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128, 0.5);
  }
  & label.Mui-focused {
    color: #4f46e5 !important;
    border-color: black;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const initialValues = {
  status: "",
};

const RecordPerPage = [
  { id: "10", title: "10" },
  { id: "20", title: "20" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "500",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const WithDrawList = () => {
  const [statusList, setStatus] = useState();
  const [detailsId, setDetailsId] = useState(null);
  const [search, setSearch] = useState("");
  const [flagName, setflagName] = useState(false);
  const [flagRiderName, setflagRiderName] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [withDrawList, setwithDrawList] = useState([]);
  const [bankList, setBankList] = useState({});

  const [sortFlag, setSortFlag] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const { collapsed, broken } = useProSidebar();
  const [approveId, setApproveId] = useState([]);


  //cancel
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const handleCloseDelete = () => {
    setOpenDeleteModel(false);
    withDrawListing(page, "", "");
  };
  const handleDeleteModel = () => setOpenDeleteModel(true);

  //Approved
  const [openApprovedModel, setOpenApprovedModel] = useState(false);
  const handleCloseApproved = () => {
    setOpenApprovedModel(false);
    withDrawListing(page, "", "");
  };
  const handleApprovedModel = () => setOpenApprovedModel(true);

  //View
  const [openViewModel, setOpenViewModel] = useState(false);
  const handleCloseView = () => setOpenViewModel(false);
  const handleViewModel = () => {
    setOpenViewModel(true);
    BankListing(detailsId);
  };

  const paymentStatusList = [
    { id: "1", title: "Approved" },
    { id: "2", title: "Declined" },
    { id: "0", title: "Requested" },
  ];

 
  const { enqueueSnackbar } = useSnackbar();


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
 

  const handleChangePage = (e, value) => {
    if (fieldName == "planName") {
      withDrawListing(value, fieldName, flagName);
    } else if (fieldName == "name") {
      withDrawListing(value, fieldName, flagRiderName);
    } else {
      withDrawListing(value, "", "");
    }
    setPage(value);
  };

  const handleChangeRow = (event) => {
    if (fieldName == "planName") {
      withDrawListing(page, fieldName, flagName, event);
    } else if (fieldName == "name") {
      withDrawListing(page, fieldName, flagRiderName, event);
    } else {
      withDrawListing(page, "", "", event);
    }
  };

  useEffect(() => {
    withDrawListing(page, "", "");
  }, [filterData, search, page]);

  const withDrawListing = async (page, name = "", flag, limit) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = limit ? limit : pageLimit;

    let queryString = `?page=${page}&search=${search}${string}&limit=${recordLimit}&sortby=${name}&sortFlag=${sortFlag}`;

    const res = await getWithDrawList(queryString);
    if (res.status === true) {
      setwithDrawList(res.data);
      setTotalRecords(res.total);
      setPageLimit(recordLimit);
      setTotalPages(Math.ceil(res.total / recordLimit));
      setSortFlag(!sortFlag);
    } else {
      setwithDrawList([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const dataShorting = (name) => {
    if (name === "planName") {
      setflagName(!flagName);
      withDrawListing(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }

    if (name === "name") {
      setflagRiderName(!flagRiderName);
      withDrawListing(page, name, !flagRiderName);
    } else {
      setflagRiderName(false);
      setSortFlag(!sortFlag);
    }
    setFieldName(name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  //withDraw status
  const withDrawStatus = (withDrawStatus) => {
    const statusColors = {
      1: {
        key: "Approved",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      2: {
        key: "Declined",
        backgroundColor: "#FDE3E1",
        color: "#ff0000",
      },
      0: {
        key: "Requested",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[withDrawStatus]}
        label={
          statusColors[withDrawStatus] ? statusColors[withDrawStatus].key : ""
        }
      />
    );
  };

  //approve status
  const handleApproveStatus = async (e, data) => {
    const body = {
      status: 1,
    };

    const res = await WithStatusUpdate(approveId, body);
    if (res && res.status === true) {
      handleCloseApproved();
      setwithDrawList();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //reject status
  const handleRejectStatus = async (e, data) => {
    const body = {
      status: 2,
    };

    const res = await WithStatusUpdate(approveId, body);
    if (res && res.status === true) {
      handleCloseDelete();
      setwithDrawList();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  //bank details

  const BankListing = async () => {
    const res = await getWithDrawDetailsList(detailsId);
    if (res.status === true) {
      setBankList(res.data);
    } else {
      setBankList([]);
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={7} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                Withdraw Request List
              </Typography>
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box
                display="flex"
                sx={{
                  justifyContent: {
                    xs: "block",
                    sm: "flex-end",
                    md: "flex-end",
                    lg: "flex-end",
                  },
                  marginTop: { xs: "20px", md: "0", lg: "0" },
                }}
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" } }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<FilterAltIcon />}
                    onClick={openFilterDrawer}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            backgroundColor: "rgb(241, 245, 249)",
            width: "98%",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              border: 1,
              borderColor: "#eaeaea",
            }}
          >
            <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
              <Table
                aria-label="customized table"
                sx={{ width: { md: "130%", lg: "110%", xl: "100%" } }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Id</StyledTableCell>

                    <StyledTableCell onClick={() => dataShorting("name")}>
                      Name
                      {flagRiderName ? (
                        <ArrowUpwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                            position: "relative",
                            top: "3px",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                            position: "relative",
                            top: "3px",
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>WithDraw To</StyledTableCell>

                    <StyledTableCell>Amount</StyledTableCell>
                    <StyledTableCell>Available Balance</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                    <StyledTableCell>Bank Details</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {withDrawList?.map((row) => {
                    return (
                      <>
                        <StyledTableRow hover sx={{ cursor: "pointer" }}>
                          <StyledTableCell component="th" scope="row">
                            {row?.id || "-"}
                          </StyledTableCell>

                          <StyledTableCell>
                            {row?.riderName || "-"}
                          </StyledTableCell>

                          <StyledTableCell>
                            <Tooltip
                              placement="bottom"
                              className="cursor-pointer"
                              title={row?.valueCode}
                            >
                              {row?.imageLink ? (
                                <Avatar
                                  component="span"
                                  variant="square"
                                  alt="imageLink"
                                  src={row?.imageLink}
                                />
                              ) : (
                                <Avatar
                                  src={ImageNotFound}
                                  alt=""
                                  variant="square"
                                />
                              )}
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row?.amount || "-"}
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            {row?.AvailableBalance || "-"}
                          </StyledTableCell>

                          <StyledTableCell>
                            {moment.parseZone(row.createdAt).format("MM-DD-YYYY LT") ||
                              "-"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {withDrawStatus(row?.status) || "-"}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              display: "flex",
                              justifyContent: "left",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {row?.status !== 1 && row.status !== 2 ? (
                              <Space size="middle">
                                <Tooltip
                                  placement="bottom"
                                  className="cursor-pointer"
                                  title="approved withdraw"
                                >
                                  <button
                                    className="action-button edit "
                                    onClick={() => {
                                      setApproveId(row.id);
                                      handleApprovedModel();
                                      setStatus(row?.status);
                                    }}
                                  >
                                    <MdOutlineDone />
                                  </button>
                                </Tooltip>
                                <Tooltip
                                  placement="bottom"
                                  title="cancel withdraw"
                                >
                                  <button
                                    className="action-button delete-btn"
                                    onClick={() => {
                                      setApproveId(row.id);

                                      handleDeleteModel();
                                    }}
                                  >
                                    <MdOutlineCancelPresentation />
                                  </button>
                                </Tooltip>
                              </Space>
                            ) : (
                              <Typography>-</Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Space size="middle">
                              <Tooltip
                                placement="bottom"
                                className="cursor-pointer"
                                title="Bank details"
                              >
                                <button
                                  className="action-button view"
                                  onClick={() => {
                                    handleViewModel();
                                    setDetailsId(row?.riderID);
                                  }}
                                >
                                  {" "}
                                  <GrView />{" "}
                                </button>
                              </Tooltip>
                            </Space>
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
              {withDrawList && withDrawList.length === 0 ? (
                <div
                  className="noDataFound"
                  style={{
                    display: "flex",
                  }}
                >
                  {" "}
                  No Data Found
                </div>
              ) : null}
            </TableContainer>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
              gap: 2,
              width: { sm: "100%", md: "50%" },
            }}
            display="flex"
            alignItems="center"
          >
            <Box>
              {" "}
              Showing{" "}
              {page * pageLimit > totalRecords
                ? totalRecords
                : page * pageLimit}{" "}
              of {totalRecords} Results
            </Box>
            <Box display="flex" sx={{ gap: 1 }} alignItems="center">
              <Typography>Records per page</Typography>
              <Select
                className="recordLimit"
                sx={{
                  color: "#000",
                  height: "40px",
                  fontSize: "15px",
                  borderRadius: "5px",
                  border: 1,
                  paddingLeft: "10px",
                  borderColor: "#000",
                  ".MuiSvgIcon-root ": {
                    fill: "black !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
                id="limites"
                name="limites"
                value={pageLimit}
                onChange={(e) => {
                  handleChangeRow(e.target.value);
                }}
              >
                {RecordPerPage.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                >
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{
                  marginTop: "8px",
                }}
              >
                <Divider sx={{ mt: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />{" "}
                <StyledFormLabel htmlFor="name">Name</StyledFormLabel>
                   <WhiteBorderTextField
                      className="input"
                      fullWidth
                      id="riderName"
                      name="riderName"
                      value={values.riderName}
                      onChange={handleInputChange}
                      sx={{ mt: 0 }}
                  />
                <Box>
                  <StyledFormLabel htmlFor="name">Status</StyledFormLabel>
                  <Controls.SelectBox
                    className="input"
                    fullWidth
                    id="status"
                    name="status"
                    value={values.status}
                    onChange={handleInputChange}
                    options={paymentStatusList}
                  />
                </Box>
              </Box>
            </Box>
            <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
              <button className="cancel-filter" onClick={closeFilterDrawer}>
                Reset
              </button>
              <button className="order-filter-button" type="submit">
                Filter
              </button>
            </Stack>
          </form>
        </Drawer>

        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Cancel Record
            </Typography>
            <label className="fontLight">
              Are you sure to want to Cancel this record?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="Cancel"
                  onClick={handleCloseDelete}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Yes"
                  onClick={(e) => handleRejectStatus(e)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openApprovedModel}
          onClose={handleCloseApproved}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Approved Record
            </Typography>
            <label className="fontLight">
              Are you sure to want to approved this record?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="Cancel"
                  onClick={handleCloseApproved}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Approved"
                  onClick={(e) => handleApproveStatus(e)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openViewModel}
          onClose={handleCloseView}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="modelBox"
            sx={{
              position: "absolute",
              top: "50%",
              left: {
                xs: "50%",
                sm: broken ? "50%" : collapsed ? "50%" : "65%",
                md: collapsed ? "50%" : "60%",
                lg: collapsed ? "50%" : "55%",
              },
              transform: "translate(-50%, -50%)",
              width: { xs: "320px", sm: "400px" },
              border: "0",
              outline: "0",
              bgcolor: "#fff",
              borderRadius: "10px",
              p: 3,
              height: "42%",
              overflow: "scroll",
            }}
          >
            <Box sx={{ display: "flex", my: 2, gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", fontWeight: "500" }}
                  >
                    Bank Name
                  </Typography>

                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
                <Box>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", marginRight: "106px", width: "100%" }}
                  >
                    {bankList?.bankName}
                  </Typography>
                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", my: 2, gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", fontWeight: "500", width: "100%" }}
                  >
                    Bank Account Holder Name
                  </Typography>

                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", marginLeft: "24px", width: "100%" }}
                  >
                    {bankList?.accountHolderName}
                  </Typography>
                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", my: 2, gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", fontWeight: "500" }}
                  >
                    Bank Account Number
                  </Typography>

                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", marginLeft: "24px", width: "100%" }}
                  >
                    {bankList?.accountNumber}
                  </Typography>
                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", my: 2, gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", fontWeight: "500" }}
                  >
                    Bank IFSC Code
                  </Typography>

                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", marginLeft: "24px", width: "100%" }}
                  >
                    {bankList?.bankIFSCCode}
                  </Typography>
                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", my: 2, gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", fontWeight: "500" }}
                  >
                    Bank Address
                  </Typography>

                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ color: "black", marginLeft: "24px", width: "100%" }}
                  >
                    {bankList?.bankAddress}
                  </Typography>
                  <Divider sx={{ my: 1, bgcolor: "rgba(201 201 201 / 40%)" }} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default WithDrawList;
