import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import {
  useTheme,
  Box,
  IconButton,
  Typography,
  Avatar,
  Tooltip,
  Grid,
  Modal,
  InputAdornment,
} from "@mui/material";

import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useProSidebar } from "react-pro-sidebar";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import { chnagePassword, logoutApi,companychnagePassword } from "../../state/action/authAction";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationLists } from "../../state/action/settingAction";
import moment from "moment";
import { updatePlayerId } from "../../state/action/notification";
import Controls from "../../Component/controls/Controls";
import KeyIcon from "@mui/icons-material/Key";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { useForm } from "../../Utils/useForm";
import { useSnackbar } from "notistack";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const initialValues = { newPassword: "", password: "", confirmPassword: "" };

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const dispatch = useDispatch();
  const userAdmin = useSelector((store) => store?.auth?.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNoti, setAnchorElNoti] = useState(null);
  const [notification, setNotification] = useState([]);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("bannerName" in fieldValues)
      temp.bannerName = fieldValues.bannerName ? "" : "This field is required.";

    if ("url" in fieldValues)
      temp.url = fieldValues.url ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const Id = useSelector((state) => state?.auth?.user?.id);

  const open = Boolean(anchorEl);
  const openNoti = Boolean(anchorElNoti);
  const handleClickUser = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickNoti = (event) => {
    setAnchorElNoti(event.currentTarget);
    notificationListing();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseNoti = () => {
    setAnchorElNoti(null);
  };

  const logoutAdmin = () => {
    dispatch(logoutApi());
  };

  const notificationListing = async () => {
    const res = await getNotificationLists();
    if (res.status === true) {
      setNotification(res.data);
    } else {
      setNotification([]);
    }
  };

useEffect(() => {

    let isInitialized = false;

    if (userAdmin?.userdata?.id) {

      const initializeOneSignal = async () => {

        if (isInitialized) {
          return;
        }


        const OneSignal = window.OneSignal || [];
        OneSignal.push(() => {
          OneSignal.init({
            appId: "53a31726-969d-4ce0-9f4e-37c3b8fd2e00",
            safari_web_id: "web.onesignal.auto.37e682f0-44ba-408e-8045-bbc89f9e01bb",
            notifyButton: {
              enable: true,
            },
            subdomainName: "jyffit",
          }).then(() => {
            OneSignal.isPushNotificationsEnabled(function (isEnabled) {
              if (isEnabled) {
                OneSignal.getUserId(function (userId) {
                  console.log("OneSignal User ID0-0-0-0:", userId);
                  setPlayerId(userId, userAdmin?.userdata?.id);
                });
              } else {
                console.log("Push notifications are not enabled yet.");
              }
            });
          });
        });
        isInitialized = true;

      };

      initializeOneSignal();
    }
  }, [userAdmin?.userdata?.id]);

  const setPlayerId = async (userId, id) => {

    const updateAdmin = await updatePlayerId(id, {
      notificationPlayerID: userId,
    });
    if (updateAdmin.status == true) {
      console.log("updateAdmin", updateAdmin);
    }
    else {
      console.log("updateAdmin", updateAdmin);
    }

  };

  //password
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //new password
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  //Confirm password
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
      id: Id,
    };
    if (showNewPassword !== showConfirmPassword) {
      setPasswordError("Passwords do not match");
    } else if (showNewPassword.length < 8) {
      setPasswordError("Password should be at least 8 characters long");
    } else {
      // Password is valid, proceed with further actions
      setPasswordError("");
      // Perform additional actions, such as sending data to the server
    }
 
  var res
    if(userAdmin.role === 1){
       res = await chnagePassword(body);
    }else{
       res = await companychnagePassword(body);
    }

    if (res && res.status === true) {
      handleCloseDelete();
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      px={2}
      py={0.7}
      sx={{
        backgroundColor: "#fff",
        boxShadow: "2",
        alignItems: "center",
        position: "sticky",
        top: "0",
        zIndex: "999",
      }}
    >
      {broken && !rtl && (
        <IconButton sx={{ margin: "0 6 0 2" }} onClick={() => toggleSidebar()}>
          <MenuOutlinedIcon
            sx={{
              color: "#000",
            }}
          />
        </IconButton>
      )}
      <Box
        display="flex"
        sx={{
          display: {
            xs: "none",
            md: "block",
            lg: "block",
          },
        }}
      ></Box>
      <Box display="flex">
        
        {userAdmin.role === 1 ?(
          <IconButton
            style={{

              color: colors.grey[500],
            }}
            id="basic-button-noti"
            aria-controls={open ? "notification" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClickNoti}
          >
            <Badge color="secondary" variant="dot">
              <NotificationsOutlinedIcon
                style={{
                  fontSize: "25px",
                }}
              />
            </Badge>
          </IconButton>
        ): null}
        

        <Box>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClickUser}
          >
            <Box
              display="flex"
              sx={{ marginLeft: "10px", alignItems: "center" }}
            >
              <Box textAlign="right" sx={{ marginRight: "10px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#000",
                    marginBottom: "0px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                  }}
                >
                  {userAdmin.role === 1 ? userAdmin.name: userAdmin.companyName}
                </Typography>
                <Typography
                  color={colors.grey[600]}
                  fontWeight="500"
                  sx={{
                    fontSize: "12px",
                    marginBottom: "0px",
                    textTransform: "capitalize",
                  }}
                >
                  
                </Typography>
              </Box>
              <Box>
                <img
                  className="avater-image"
                  alt="profile user"
                  width="40px"
                  height="40px"
                  src={"/assets/images/user.jpg"}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
            </Box>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#fff",
              },
              marginLeft: "5px",
            }}
          >
            <MenuItem
              sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}
              onClick={(e) => {
                handleDeleteModel();
              }}
            >
              <KeyIcon
                sx={{
                  color: "rgb(107, 114, 128)",
                  fontSize: "25px",
                  marginRight: "7px",
                }}
              />
              Change Password
            </MenuItem>
            <MenuItem
              sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}
              onClick={() => logoutAdmin()}
            >
              <LogoutIcon
                sx={{
                  color: "rgb(107, 114, 128)",
                  fontSize: "25px",
                  marginRight: "7px",
                }}
              />
              Logout
            </MenuItem>
          </Menu>
          <Menu
            id="notification"
            anchorEl={anchorElNoti}
            open={openNoti}
            onClose={handleCloseNoti}
            MenuListProps={{
              "aria-labelledby": "basic-button-noti",
            }}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#fff",
              },
              height: "500px",
            }}
          >
            {notification?.map((row) => {
              return (
                <>
                  <MenuItem
                    onClick={handleClose}
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Avatar
                        aria-label="recipe"
                        sx={{
                          marginLeft: " 16px",
                          marginTop: "16px",
                        }}
                        src={row?.notificationIcon}
                      ></Avatar>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "20px",
                          marginTop: "15px",
                        }}
                      >
                        <Box sx={{ flex: "1 0 auto" }}>
                          <Typography
                            component="div"
                            variant="h5"
                            sx={{ color: "black" }}
                          >
                            <Tooltip title={row?.title}>
                              <Button sx={{ padding: "2px" }}>
                                {row?.title?.length < 30
                                  ? row?.title
                                  : row?.title.slice(0, 30) + "..."}
                              </Button>
                            </Tooltip>
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                            sx={{ color: "black" }}
                          >
                            <Tooltip title={row?.description}>
                              <Button sx={{ padding: "2px" }}>
                                {row?.description.slice(0, 21) + "..."}
                              </Button>
                            </Tooltip>
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                            sx={{ color: "black" }}
                          >
                            {moment.parseZone(row.createdAt).format("MM-DD-YYYY LT")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </MenuItem>
                </>
              );
            })}
          </Menu>
        </Box>
      </Box>

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styles}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Change Password
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box>
              <Box sx={{ marginTop: "30px" }}>
                <FormControl
                  sx={{ marginTop: "10px", width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{ color: "#000", bgcolor: "#fff", lineHeight: "1" }}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "12px 14px !important",
                      },

                      "& .MuiOutlinedInput-notchedOutline": {
                        paddingTop: "10px!important",
                        borderColor: "rgba(107, 114, 128, .5)",

                        borderRadius: "7px!important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "blue",
                      },
                      paddingTop: "0px",
                      color: "#000",
                    }}
                    id="outlined-adornment-password"
                    name="currentPassword"
                    value={values.currentPassword}
                    onChange={handleInputChange}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ color: "#000" }}
                          aria-label="toggle password visib`ility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl
                  sx={{ marginTop: "10px", width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{ color: "#000", bgcolor: "#fff", lineHeight: "1" }}
                  >
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "12px 14px !important",
                      },

                      "& .MuiOutlinedInput-notchedOutline": {
                        paddingTop: "10px!important",
                        borderColor: "rgba(107, 114, 128, .5)",

                        borderRadius: "7px!important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "blue",
                      },
                      paddingTop: "0px",
                      color: "#000",
                    }}
                    id="outlined-adornment-password"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleInputChange}
                    type={showNewPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ color: "#000" }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl
                  sx={{ marginTop: "10px", width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{ color: "#000", bgcolor: "#fff", lineHeight: "1" }}
                  >
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "12px 14px !important",
                      },

                      "& .MuiOutlinedInput-notchedOutline": {
                        paddingTop: "10px!important",
                        borderColor: "rgba(107, 114, 128, .5)",

                        borderRadius: "7px!important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "blue",
                      },
                      paddingTop: "0px",
                      color: "#000",
                    }}
                    id="outlined-adornment-password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleInputChange}
                    type={showConfirmPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ color: "#000" }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {passwordError && <div>{passwordError}</div>}
                </FormControl>
              </Box>
              <Grid container style={{ marginTop: "2rem" }}>
                <Grid item xs={9} className="text-end">
                  <Controls.Button
                    sx={{
                      backgroundColor: "#fff",
                      ":hover": { color: "#fff" },
                      marginRight: { xs: "10px", sm: "0px" },
                      color: "#000",
                    }}
                    text="Cancel"
                    onClick={handleCloseDelete}
                  />
                </Grid>
                <Grid item xs={3} className="text-end">
                  <Controls.Button
                    sx={{ marginRight: "7px" }}
                    text="Save"
                    type="submit"
                  />
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default Topbar;
