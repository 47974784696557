import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Typography,
  InputBase,
  Pagination,
  Tooltip,
  Divider,
  Chip,
  Modal,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
} from "@mui/material";
import Controls from "../../Component/controls/Controls";
import { Drawer, FormLabel } from "@mui/material";
import { useForm } from "../../Utils/useForm";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Box, Grid } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Space } from "antd";
import { GrView } from "react-icons/gr";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import OrderRequestList from "./OrderRequestList";
import {
  getOrderList,
  getRiderDropdownList,
  getUserDropdownList,
} from "../../state/action/orderAction";
import {
  getOrderDropdown,
} from "../../state/action/transactionAction";
import { useSnackbar } from "notistack";
import moment from "moment";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const initialValues = {
  userID: "",
  riderID: "",
  orderNumber: "",
  orderStatus: "",
};

const RecordPerPage = [
  { id: "10", title: "10" },
  { id: "20", title: "20" },
];

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#f6f9fb",
    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "5px",
    paddingRight: "5px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "rgb(250 250 250)!important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

export default function CustomizedTables() {
  const [orderLists, setOrderLists] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [riderLists, setRiderLists] = useState([]);

  const [deleteId, setDeleteId] = useState(null);
  const [search, setSearch] = useState("");
  const [flagName, setflagName] = useState(false);
  const [flagNumber, setflagNumber] = useState(false);
  const [flagRiderName, setflagRiderName] = useState(false);
  const [page, setPage] = useState(1);
  const [ParameterList, setParameterList] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);

  const [sortFlag, setSortFlag] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [fieldName, setFieldName] = useState("");

  const [id, setId] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [stateData, setStateData] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [userId, setUserId] = useState("");
  const [paymentModeList, setPaymentModeList] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();

  const [value, setValue] = useState("1");
  const [selectedOption, setSelectedOption] = useState(null);


  //get paymentmode

  const getModeList = async () => {
    const res = await getOrderDropdown();
    if (res.status === true) {
      setPaymentModeList(res.data);
    } else {
      setPaymentModeList([]);
    }
  };

  // paymentmode List
  useEffect(() => {
    getModeList();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);
  };

  const handleChangePage = (e, value) => {
    if (fieldName == "userName") {
      getOrderListing(value, fieldName, flagName);
    } else if (fieldName == "orderNumber") {
      getOrderListing(value, fieldName, flagNumber);
    } else if (fieldName == "riderName") {
      getOrderListing(value, fieldName, flagRiderName);
    } else {
      getOrderListing(value, "", "");
    }
    setPage(value);
  };

  const handleChangeRow = (event) => {
    if (fieldName == "userName") {
      getOrderListing(page, fieldName, flagName, event);
    } else if (fieldName == "orderNumber") {
      getOrderListing(page, fieldName, flagNumber, event);
    } else if (fieldName == "riderName") {
      getOrderListing(page, fieldName, flagRiderName, event);
    } else {
      getOrderListing(page, "", "", event);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  // Order List
  useEffect(() => {
    getOrderListing(page, "", "");
  }, [state, filterData, search, value]);

  const getOrderListing = async (page, name = "", flag, limit) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    let recordLimit = limit ? limit : pageLimit;

    let queryString = `?status=${value == 2 ? 0 : 1
      }&page=${page}&search=${search}${string}&limit=${recordLimit}&sortby=${name}&sortFlag=${flag}`;

    const res = await getOrderList(queryString);
    if (res.status === true) {
      setOrderLists(res.data);
      setTotalRecords(res.totoalOrder);
      setTotalPages(Math.ceil(res.totoalOrder / recordLimit));
      setPageLimit(recordLimit);
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
      setPage(page);
    } else {
      setOrderLists([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  //user dropdownlist
  useEffect(() => {
    getriderListing(page, "", "");
  }, []);
  const getriderListing = async () => {
    setOpenBreakdrop(true);
    const res = await getRiderDropdownList();
    if (res.status === true) {
      setRiderLists(res.data);

      setOpenBreakdrop(false);
    } else {
      setRiderLists([]);
    }
    setFilter(false);
  };

  //user dropdownlist
  useEffect(() => {
    getuserListing(page, "", "");
  }, []);

  const getuserListing = async () => {
    setOpenBreakdrop(true);
    const res = await getUserDropdownList();
    if (res.status === true) {
      setUserLists(res.data);

      setOpenBreakdrop(false);
    } else {
      setUserLists([]);
    }
    setFilter(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  //orderstatus
  const orderStatus = (orderStatus) => {
    const statusColors = {
      0: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Order Pickup Schedule",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      2: {
        key: " Pickedup Package",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      3: {
        key: " Packagein Transit",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      4: {
        key: "Delivered",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      5: {
        key: "Cancelled",
        backgroundColor: "#FDE3E1",
        color: "#ff0000",
      },
      6: {
        key: "Failed",
        backgroundColor: "#FDE3E1",
        color: "#ff0000",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "13px",
        }}
        sx={statusColors[orderStatus]}
        label={statusColors[orderStatus] ? statusColors[orderStatus].key : ""}
      />
    );
  };

  //payment status
  const paymentStatus = (paymentStatus) => {
    const statusColors = {
      1: {
        key: "Success",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      2: {
        key: "Failed",
        backgroundColor: "#FDE3E1",
        color: "#ff0000",
      },
      3: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      4: {
        key: "Refund",
        backgroundColor: "#f44336",
        color: "#ffffff",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[paymentStatus]}
        label={
          statusColors[paymentStatus] ? statusColors[paymentStatus].key : ""
        }
      />
    );
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  //order status List
  const OrderstatusList = [
    { id: "0", title: "Pending" },
    { id: "1", title: "Order Pickup Schedule" },
    { id: "2", title: "Pickedup Package" },
    { id: "3", title: "Packagein Transit" },
    { id: "4", title: "Delivered" },
    { id: "5", title: "Cancelled" },
  ];

  //payment status List
  const paymentstatusList = [
    { id: "1", title: "Success" },
    { id: "2", title: "Failed" },
    { id: "3", title: "Pending" },
  ];

  const dataShorting = (key) => {
    if (key === "userName") {
      setflagName(!flagName);
      getOrderListing(page, key, !flagName);
    } else {
      setflagName(false);
      setSortFlag(false);
    }

    if (key === "orderNumber") {
      setflagNumber(!flagNumber);
      getOrderListing(page, key, !flagNumber);
    } else {
      setflagNumber(false);
      setSortFlag(false);
    }
    if (key === "riderName") {
      setflagRiderName(!flagRiderName);
      getOrderListing(page, key, !flagRiderName);
    } else {
      setflagRiderName(false);
      setSortFlag(false);
    }
    setSortBy(key);
    setFieldName(key);
  };

  return (
    <>
      <Box>
        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={7} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                Order
              </Typography>
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box
                display="flex"
                sx={{
                  justifyContent: {
                    xs: "block",
                    sm: "flex-end",
                    md: "flex-end",
                    lg: "flex-end",
                  },
                  marginTop: { xs: "20px", md: "0", lg: "0" },
                }}
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setTimeout(() => {
                        setSearch(e.target.value);
                      }, 800);
                    }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  startIcon={<FilterAltIcon />}
                  onClick={openFilterDrawer}
                  sx={{
                    marginLeft: "15px",
                    borderRadius: "50px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    color: "#fff",
                    ":hover": { bgcolor: "rgb(30, 41, 59)" },
                    background: "rgb(30, 41, 59)",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "none",
                  }}
                >
                  Filter
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "rgb(241, 245, 249)",
              mx: 2,
            }}
          >
            <Box
              sx={{
                paddingTop: "10px",
                paddingLeft: "5px",
                backgroundColor: "#fff",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                overflow: "scroll",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Order List"
                  value="1"
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                />
                <Tab
                  label="Order Pending List"
                  value="2"
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                />
              </TabList>
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
              <TabPanel value="1">
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderTopRightRadius: "20px",
                    borderTopLeftRadius: "20px",
                    overflow: "scroll",
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table
                      aria-label="customized table"
                      sx={{ width: { md: "180%", lg: "165%", xl: "100%" } }}
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            <Button
                              variant="text"
                              sx={{
                                ":hover": { bgcolor: "transparent" },
                                paddingLeft: "0",
                                color: "#000",
                                fontSize: "13px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                justifyContent: "left",
                              }}
                            >
                              Id
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => dataShorting("orderNumber")}
                          >
                            Order Number
                            {flagNumber ? (
                              <ArrowUpwardIcon
                                sx={{
                                  color: "rgba(107, 114, 128, .5)",
                                  fontSize: "17px",
                                  marginLeft: "3px",
                                }}
                              />
                            ) : (
                              <ArrowDownwardIcon
                                sx={{
                                  color: "rgba(107, 114, 128, .5)",
                                  fontSize: "17px",
                                  marginLeft: "3px",
                                }}
                              />
                            )}
                          </StyledTableCell>

                          <StyledTableCell>
                            <Button
                              variant="text"
                              sx={{
                                ":hover": { bgcolor: "transparent" },
                                paddingLeft: "0",
                                color: "#000",
                                fontSize: "13px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                              onClick={() => dataShorting("userName")}
                            >
                              Customer Name
                              {flagName ? (
                                <ArrowUpwardIcon
                                  sx={{
                                    color: "rgba(107, 114, 128, .5)",
                                    fontSize: "17px",
                                    marginLeft: "3px",
                                  }}
                                />
                              ) : (
                                <ArrowDownwardIcon
                                  sx={{
                                    color: "rgba(107, 114, 128, .5)",
                                    fontSize: "17px",
                                    marginLeft: "3px",
                                  }}
                                />
                              )}
                            </Button>
                          </StyledTableCell>

                          <StyledTableCell>
                            <Button
                              variant="text"
                              sx={{
                                ":hover": { bgcolor: "transparent" },
                                paddingLeft: "0",
                                color: "#000",
                                fontSize: "13px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                              onClick={() => dataShorting("riderName")}
                            >
                              Rider Name
                              {flagRiderName ? (
                                <ArrowUpwardIcon
                                  sx={{
                                    color: "rgba(107, 114, 128, .5)",
                                    fontSize: "17px",
                                    marginLeft: "3px",
                                  }}
                                />
                              ) : (
                                <ArrowDownwardIcon
                                  sx={{
                                    color: "rgba(107, 114, 128, .5)",
                                    fontSize: "17px",
                                    marginLeft: "3px",
                                  }}
                                />
                              )}
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              variant="text"
                              sx={{
                                ":hover": { bgcolor: "transparent" },
                                paddingLeft: "0",
                                color: "#000",
                                fontSize: "13px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              Vehicle Type
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              variant="text"
                              sx={{
                                ":hover": { bgcolor: "transparent" },
                                paddingLeft: "0",
                                color: "#000",
                                fontSize: "13px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              Pick up Location
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              variant="text"
                              sx={{
                                ":hover": { bgcolor: "transparent" },
                                paddingLeft: "0",
                                color: "#000",
                                fontSize: "13px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              Drop Location
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              variant="text"
                              sx={{
                                ":hover": { bgcolor: "transparent" },
                                paddingLeft: "0",
                                color: "#000",
                                fontSize: "13px",
                                fontWeight: "600",
                                textTransform: "capitalize",
                              }}
                            >
                              Amount
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              variant="text"
                              sx={{
                                ":hover": { bgcolor: "transparent" },
                                paddingLeft: "0",
                                color: "#000",
                                fontSize: "13px",
                                fontWeight: "600",
                                textTransform: "capitalize",
                              }}
                            >
                              Order Status
                            </Button>
                          </StyledTableCell>

                          <StyledTableCell>
                            <Button
                              variant="text"
                              sx={{
                                ":hover": { bgcolor: "transparent" },
                                paddingLeft: "0",
                                color: "#000",
                                fontSize: "13px",
                                fontWeight: "600",
                                textTransform: "capitalize",
                              }}
                            >
                              Payment Status
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              variant="text"
                              sx={{
                                ":hover": { bgcolor: "transparent" },
                                paddingLeft: "0",
                                color: "#000",
                                fontSize: "13px",
                                fontWeight: "600",
                                textTransform: "capitalize",
                              }}
                            >
                              Payment Type
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              variant="text"
                              sx={{
                                ":hover": { bgcolor: "transparent" },
                                paddingLeft: "0",
                                color: "#000",
                                fontSize: "13px",
                                fontWeight: "600",
                                textTransform: "capitalize",
                                justifyContent: "left",
                              }}
                            >
                              Date
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              variant="text"
                              sx={{
                                ":hover": { bgcolor: "transparent" },
                                paddingLeft: "0",
                                color: "#000",
                                fontSize: "13px",
                                fontWeight: "600",
                                textTransform: "capitalize",
                                justifyContent: "left",
                              }}
                            >
                              Action
                            </Button>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderLists?.map((row) => {
                          return (
                            <>
                              <StyledTableRow
                                hover
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  setId(row?.id);
                                  navigate("/orderdetails", {
                                    state: row.id,
                                  });
                                }}
                              >
                                <StyledTableCell component="th" scope="row">
                                  {row.id || "-"}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {row?.orderNumber || "-"}
                                </StyledTableCell>
                                <StyledTableCell
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      color: "blue",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      navigate("/userdetails", {
                                        state: {
                                          user: row?.userID,
                                        },
                                      });
                                    }}
                                  >
                                    {row?.userName || "-"}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      color: "blue",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      navigate("/riderdetails", {
                                        state: row?.riderID,
                                      });
                                    }}
                                  >
                                    {row?.riderName || "-"}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row?.vehicleName || "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row?.startLocation || "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row?.endLocation || "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row?.paybleCost || "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {orderStatus(row?.orderStatus) || "-"}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {paymentStatus(row?.paymentStatus) || "-"}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row?.paymentmodename || "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {moment.parseZone(row.createdAt).format(
                                      "MM-DD-YYYY LT"
                                    )}
                                  </Typography>
                                </StyledTableCell>

                                <StyledTableCell
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <Space size="middle">
                                    <Tooltip
                                      placement="bottom"
                                      title="View Order"
                                    >
                                      <button
                                        className="action-button view"
                                        onClick={() => {
                                          navigate("/orderdetails", {
                                            state: row.id,
                                          });
                                        }}
                                      >
                                        {" "}
                                        <GrView />{" "}
                                      </button>
                                    </Tooltip>
                                  </Space>
                                </StyledTableCell>
                              </StyledTableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </Table>
                    {orderLists && orderLists.length === 0 ? (
                      <div
                        className="noDataFound"
                        style={{
                          display: "flex",
                        }}
                      >
                        {" "}
                        No Data Found
                      </div>
                    ) : null}
                  </TableContainer>
                </Box>
                <Box
                  sx={{
                    display: {
                      xs: "block",
                      sm: "flex",
                      md: "flex",
                      lg: "flex",
                    },
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: {
                      xs: "10px",
                      sm: "20px",
                      md: "20px",
                      lg: "20px",
                    },
                    marginRight: {
                      xs: "10px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "14px",
                      marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                      gap: 2,
                      width: { sm: "100%", md: "50%" },
                    }}
                    display="flex"
                    alignItems="center"
                  >
                    <Box>
                      {" "}
                      Showing{" "}
                      {page * pageLimit > totalRecords
                        ? totalRecords
                        : page * pageLimit}{" "}
                      of {totalRecords} Results
                    </Box>
                    <Box display="flex" sx={{ gap: 1 }} alignItems="center">
                      <Typography>Records per page</Typography>
                      <Select
                        className="recordLimit"
                        sx={{
                          color: "#000",
                          height: "40px",
                          fontSize: "15px",
                          borderRadius: "5px",
                          border: 1,
                          paddingLeft: "10px",
                          borderColor: "#000",
                          ".MuiSvgIcon-root ": {
                            fill: "black !important",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                        }}
                        id="limites"
                        name="limites"
                        value={pageLimit}
                        onChange={(e) => {
                          handleChangeRow(e.target.value);
                        }}
                      >
                        {RecordPerPage.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name || item.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Box>
                  </Box>
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handleChangePage}
                    variant="outlined"
                    shape="rounded"
                    sx={{
                      "& .MuiButtonBase-root ": {
                        color: "#000 !important",
                      },
                      "& .Mui-selected ": {
                        background: "#4b4b4e70 !important",
                      },
                      "& .Mui-disabled ": {
                        background: "#6c696966 !important",
                        color: "#000000 !important",
                        opacity: 1,
                      },
                    }}
                  />
                </Box>
              </TabPanel>

              <TabPanel value="2">
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderTopRightRadius: "20px",
                    borderTopLeftRadius: "20px",
                  }}
                >
                  <OrderRequestList
                    data={orderLists}
                    dataShorting={dataShorting}
                    flagName={flagName}
                    flagNumber={flagNumber}
                    flagRiderName={flagRiderName}
                    handleChangeRow={handleChangeRow}
                    pageLimit={pageLimit}
                    totalPages={totalPages}
                    page={page}
                    totalRecords={totalRecords}
                    setPage={setPage}
                    fieldName={fieldName}
                    handleChangePage={handleChangePage}
                  />
                </Box>
              </TabPanel>
            </Box>
          </Box>
        </TabContext>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                >
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{
                  marginTop: "8px",
                }}
              >
                <Divider sx={{ mt: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />

                <StyledFormLabel htmlFor="name">Order Number</StyledFormLabel>
                <Controls.Input
                  className="input"
                  fullWidth
                  id="orderNumber"
                  name="orderNumber"
                  value={values.orderNumber}
                  onChange={handleInputChange}
                  sx={{ mt: 0 }}
                />

                <StyledFormLabel htmlFor="name">User Name</StyledFormLabel>
                <Box sx={{ marginTop: "10px" }}>
                  <Autocomplete
                    id="userID"
                    name="userID"
                    onChange={
                      (e, newvalues) => {
                        setValues({
                          ...values,
                          userID: newvalues.id
                        })
                      }
                    }
                    options={userLists}
                    variant="outlined"
                    color="primary"
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Select a user" />}
                  />
                </Box>

                <StyledFormLabel htmlFor="name">Rider Name</StyledFormLabel>
                <Box sx={{ marginTop: "10px" }}>
                  <Autocomplete
                    id="riderID"
                    name="riderID"
                    onChange={
                      (e, newvalues) => {
                        setValues({
                          ...values,
                          riderID: newvalues.id
                        })
                      }
                    }
                    options={riderLists}
                    variant="outlined"
                    color="primary"
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Select a rider" />}
                  />
                </Box>

                <StyledFormLabel htmlFor="hsnCode">Status</StyledFormLabel>

                <Controls.SelectBox
                  className="input"
                  fullWidth
                  id="orderStatus"
                  name="orderStatus"
                  value={values.orderStatus}
                  onChange={handleInputChange}
                  options={OrderstatusList}
                />

                <StyledFormLabel htmlFor="hsnCode">
                  Payment Status
                </StyledFormLabel>

                <Controls.SelectBox
                  className="input"
                  fullWidth
                  id="paymentStatus"
                  name="paymentStatus"
                  value={values.paymentStatus}
                  onChange={handleInputChange}
                  options={paymentstatusList}
                />

                <StyledFormLabel htmlFor="taxRate">
                  Payment Type
                </StyledFormLabel>
                <Controls.SelectBox
                  className="input"
                  fullWidth
                  id="paymentMode"
                  name="paymentMode"
                  value={values.paymentMode}
                  onChange={handleInputChange}
                  options={paymentModeList}
                />

              </Box>
            </Box>
            <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
              <button className="cancel-filter" onClick={closeFilterDrawer}>
                Reset
              </button>
              <button className="order-filter-button" type="submit">
                Filter
              </button>
            </Stack>
          </form>
        </Drawer>
      </Box>
    </>
  );
}
