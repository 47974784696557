import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  TextField,
  useTheme,
  Divider,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { useLocation, useNavigate } from "react-router";
import { styled } from "@mui/system";
import { useForm } from "../../Utils/useForm";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  getOrderDetails,
  getOrderStatusUpdate,
} from "../../state/action/orderAction";
import PhoneIcon from "@mui/icons-material/Phone";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Controls from "../../Component/controls/Controls";
import { useSnackbar } from "notistack";
import moment from "moment";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const initialValues = {
  remark: "",
};

const OrderDetailsPage = () => {
  const [orderList, setOrderList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { state } = useLocation();

  //get the details
  const getDetails = async (state) => {
    const res = await getOrderDetails(state);
    if (res && res.status) {
      setOrderList(res?.data);
      setUserList(res?.data?.UserData);
      setPaymentList(res?.data?.paymentSummary);
    }
  };

  useEffect(() => {
    getDetails(state);
  }, [state]);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  //payment status
  const paymentStatus = (paymentStatus) => {
    const statusColors = {
      1: {
        key: "Success",
        color: "green",
      },
      2: {
        key: "Failed",
        color: "red",
      },
      3: {
        key: "Pending",
        color: "#fc7e00",
      },
      4: {
        key: "Refund",
        color: "red",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[paymentStatus]}
        label={
          statusColors[paymentStatus] ? statusColors[paymentStatus].key : ""
        }
      />
    );
  };

  //orderstatus
  const orderStatus = (orderStatus) => {
    const statusColors = {
      0: {
        key: "Pending",
        color: "#fc7e00",
      },
      1: {
        key: "Order Pickup Schedule",
        color: "green",
      },
      2: {
        key: " Pickup Package",
        color: "green",
      },
      3: {
        key: " Package in Transit",
        color: "green",
      },
      4: {
        key: "Delivered",
        color: "green",
      },
      5: {
        key: "Cancelled",
        color: "#ff0000",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "13px",
        }}
        sx={statusColors[orderStatus]}
        label={statusColors[orderStatus] ? statusColors[orderStatus].key : ""}
      />
    );
  };

  //order status List
  const OrderstatusList = [
    { id: 0, title: "Pending" },
    { id: 1, title: "Order Pickup Schedule" },
    { id: 2, title: "Pickedup Package" },
    { id: 3, title: "Packagein Transit" },
    { id: 4, title: "Delivered" },
    { id: 5, title: "Cancelled" },
  ];

  //status Change
  const handleChangeStatus = async (e) => {
    const payload = {
      orderStatus: e.target.value,
    };
    const res = await getOrderStatusUpdate(state, payload);
    if (res && res.status === true) {
      navigate("/orders");
      setOrderList({
        ...orderList,
        status: e.target.value,
      });
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  return (
    <>
      <Box>
        <Typography
          sx={{ color: "black", display: "flex", fontWeight: "bold" }}
        >
          Order Number : {orderList?.orderNumber}
        </Typography>
        <Box sx={{ flexGrow: 1, marginTop: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "16.5px",
                  fontWeight: "600",
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                Parcel Details
              </Typography>
            </Grid>

            <Grid item xs={6} md={6}>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "16.5px",
                  fontWeight: "600",
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                Payment Details
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} sx={{ marginTop: "13px" }}>
              <Item sx={{ pl: 2 }}>
                <Typography align="left" sx={{ color: "black" }}>
                  Parcel Type : {orderList?.goodsTypeName}
                </Typography>

                <Typography align="left" sx={{ color: "black" }}>
                  Item values(Amount) : {orderList?.itemValues}
                </Typography>
              </Item>
            </Grid>

            <Grid item xs={6} md={6} sx={{ marginTop: "13px" }}>
              <Item sx={{ height: "61px" }}>
                <Typography align="left" sx={{ color: "black" }}>
                  Payment Type : {orderList?.paymentmodename}
                </Typography>

                <Typography align="left" sx={{ color: "black" }}>
                  Payment Status :{paymentStatus(orderList?.paymentStatus)}
                </Typography>

                <Typography sx={{ color: "black" }}></Typography>
              </Item>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, marginTop: "15px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "16.5px",
                  fontWeight: "600",
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                Pickup Address
              </Typography>
            </Grid>

            <Grid item xs={6} md={6}>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "16.5px",
                  fontWeight: "600",
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                Delivery Address
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} sx={{ marginTop: "13px" }}>
              <Item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    my: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <AccountBoxIcon sx={{ color: "black" }} />
                    <span
                      style={{
                        fontSize: "15px",
                        marginLeft: "10px",
                        color: "black",
                      }}
                    >
                      {userList?.name}
                    </span>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    my: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <PhoneIcon sx={{ color: "black" }} />
                    <span
                      style={{
                        fontSize: "15px",
                        marginLeft: "10px",
                        color: "black",
                      }}
                    >
                      {userList?.countryCode}
                      {userList?.mobile}
                    </span>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    marginTop: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <LocationOnIcon sx={{ color: "black" }} />
                    <span style={{ marginLeft: "10px", color: "black" }}>
                      {orderList?.startLocation}
                    </span>
                  </div>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={6} md={6} sx={{ marginTop: "13px" }}>
              <Item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    my: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <AccountBoxIcon sx={{ color: "black" }} />
                    <span
                      style={{
                        fontSize: "15px",
                        marginLeft: "10px",
                        color: "black",
                      }}
                    >
                      {orderList?.receiverName}
                    </span>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    my: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <PhoneIcon sx={{ color: "black" }} />
                    <span
                      style={{
                        fontSize: "15px",
                        marginLeft: "10px",
                        color: "black",
                      }}
                    >
                      {orderList?.countryCode}
                      {orderList?.receiverPhoneNumber}
                    </span>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    marginTop: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <LocationOnIcon sx={{ color: "black" }} />
                    <span style={{ marginLeft: "10px", color: "black" }}>
                      {orderList?.endLocation}
                    </span>
                  </div>
                </Box>
              </Item>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, marginTop: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "16.5px",
                  fontWeight: "600",
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                Details
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, marginTop: "15px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item>
                <Grid
                  container
                  spacing={2}
                  sx={{ boxShadow: "none", px: 1, marginTop: "-10px" }}
                >
                  <Grid xs={6} md={6} sx={{ paddingTop: "10px", px: 2 }}>
                    <Typography
                      textAlign="left"
                      sx={{ color: "black", marginTop: "4px" }}
                    >
                      Order Status
                    </Typography>
                  </Grid>
                  <Grid xs={6} md={6} sx={{ paddingTop: "10px" }}>
                    <Typography textAlign="end" sx={{ color: "black" }}>
                      {orderStatus(orderList?.orderStatus)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ boxShadow: "none" }}>
                  <Grid item xs={6} md={6}>
                    <Item sx={{ boxShadow: "none" }}>
                      <Typography sx={{ color: "black", display: "flex" }}>
                        Vehicle Type
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Item
                      sx={{
                        boxShadow: "none",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Typography sx={{ color: "black" }}>
                        {orderList?.vehicleTypeName}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ boxShadow: "none", px: 1 }}>
                  <Grid xs={6} md={6} sx={{ paddingTop: "12px", px: 2 }}>
                    <Typography textAlign="left" sx={{ color: "black" }}>
                      Order Type
                    </Typography>
                  </Grid>
                  <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                    <Typography textAlign="end" sx={{ color: "black" }}>
                      {orderList?.orderTypeName}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  sx={{ boxShadow: "none", px: 1, marginTop: "-10px" }}
                >
                  <Grid xs={6} md={6} sx={{ paddingTop: "12px", px: 2 }}>
                    <Typography textAlign="left" sx={{ color: "black" }}>
                      Created Date
                    </Typography>
                  </Grid>
                  <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                    <Typography textAlign="end" sx={{ color: "black" }}>
                      {orderList?.createdAt ? (
                        <Typography>
                          {moment.parseZone(orderList.createdAt).format("MM-DD-YYYY LT")}
                        </Typography>
                      ) : (
                        <Typography>-</Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ boxShadow: "none", px: 1, marginTop: "-10px" }}
                >
                  <Grid xs={6} md={6} sx={{ paddingTop: "12px", px: 2 }}>
                    <Typography textAlign="left" sx={{ color: "black" }}>
                      Order StartTime
                    </Typography>
                  </Grid>
                  <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                    <Typography textAlign="end" sx={{ color: "black" }}>
                      {orderList?.orderStartTime ? (
                        <Typography>
                          {moment.parseZone(orderList?.orderStartTime).format(
                            "MM-DD-YYYY LT"
                          )}
                        </Typography>
                      ) : (
                        <Typography>-</Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ boxShadow: "none", px: 1, marginTop: "-10px" }}
                >
                  <Grid xs={6} md={6} sx={{ paddingTop: "12px", px: 2 }}>
                    <Typography textAlign="left" sx={{ color: "black" }}>
                      Order EndTime
                    </Typography>
                  </Grid>
                  <Grid xs={6} md={6} sx={{ paddingTop: "12px" }}>
                    <Typography textAlign="end" sx={{ color: "black" }}>
                      {orderList?.orderEndTime ? (
                        <Typography>
                          {moment.parseZone(orderList?.orderEndTime).format(
                            "MM-DD-YYYY LT"
                          )}
                        </Typography>
                      ) : (
                        <Typography>-</Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "16.5px",
                  fontWeight: "600",
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                Payment Summary
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "15px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item>
                <Grid container spacing={2} sx={{ boxShadow: "none" }}>
                  <Grid item xs={6} md={6}>
                    <Item sx={{ boxShadow: "none" }}>
                      <Typography sx={{ color: "black", display: "flex" }}>
                        Trip Amount(Incl.Toll)
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Item
                      sx={{
                        boxShadow: "none",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Typography sx={{ color: "black" }}>
                        {orderList?.CountryCode} {paymentList?.tripAmount}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ boxShadow: "none", px: 1 }}>
                  <Grid xs={6} md={6} sx={{ paddingTop: "10px", px: 2 }}>
                    <Typography textAlign="left" sx={{ color: "black" }}>
                      Discount
                    </Typography>
                  </Grid>
                  <Grid xs={6} md={6} sx={{ paddingTop: "10px" }}>
                    <Typography textAlign="end" sx={{ color: "black" }}>
                      {orderList?.CountryCode} {paymentList?.discount}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ boxShadow: "none", px: 1, pt: 1 }}
                >
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ boxShadow: "none", px: 1, pt: 1 }}
                >
                  <Grid xs={6} md={6} sx={{ paddingTop: "10px", px: 2 }}>
                    <Typography textAlign="left" sx={{ color: "black" }}>
                      Rider Tips
                    </Typography>
                  </Grid>
                  <Grid xs={6} md={6} sx={{ paddingTop: "10px" }}>
                    <Typography textAlign="end" sx={{ color: "black" }}>
                      {orderList?.CountryCode} {paymentList?.tips}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />
                <Grid
                  container
                  spacing={2}
                  sx={{ boxShadow: "none", px: 1, pt: 1 }}
                >
                  <Grid xs={6} md={6} sx={{ paddingTop: "10px", px: 2 }}>
                    <Typography
                      textAlign="left"
                      sx={{ color: "black", fontWeight: "600" }}
                    >
                      Net Amount
                    </Typography>
                  </Grid>

                  <Grid xs={6} md={6} sx={{ paddingTop: "10px" }}>
                    <Typography
                      textAlign="end"
                      sx={{
                        color: "black",
                        fontWeight: "600",
                        marginTop: "5px",
                      }}
                    >
                      {orderList?.CountryCode} {orderList?.netAmount}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  sx={{ boxShadow: "none", px: 1, py: 1 }}
                ></Grid>
                <Grid container spacing={2} sx={{ boxShadow: "none", px: 1 }}>
                  <Grid xs={6} md={6} sx={{ paddingTop: "10px", px: 2 }}>
                    <Typography textAlign="left" sx={{ color: "black" }}>
                      Parking Charge
                    </Typography>
                  </Grid>
                  <Grid xs={6} md={6} sx={{ paddingTop: "10px" }}>
                    <Typography textAlign="end" sx={{ color: "black" }}>
                      {orderList?.CountryCode} {paymentList?.parkingCharge}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />
                <Grid
                  container
                  spacing={2}
                  sx={{ boxShadow: "none", px: 1, pt: 1 }}
                >
                  <Grid xs={6} md={6} sx={{ paddingTop: "15px", px: 2 }}>
                    <Typography
                      variant="h5"
                      textAlign="start"
                      sx={{ color: "black", fontWeight: "600" }}
                    >
                      Total
                    </Typography>
                  </Grid>
                  <Grid xs={6} md={6} sx={{ paddingTop: "15px" }}>
                    <Typography
                      variant="h5"
                      textAlign="end"
                      sx={{ color: "black", fontWeight: "600" }}
                    >
                      {orderList?.CountryCode} {paymentList?.paybleCost}
                    </Typography>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default OrderDetailsPage;
